import {Umap} from "../../../models/Umap";
import generateUmap, {UmapInfo} from "../../rootatlas/data/UmapCreator";
import {
    AppFeature,
    AppFeatureState,
    CancelablePromise,
    roundExpression,
    timeout
} from "../../rootatlas/data/AppFeature";
import {ColorMap} from "../../../models/ColorMap";
import {provideLabeledMap, RootInfo, RootSectionColorMap} from "../../rootatlas/data/RootCreator";
import {Dataset} from "../../../models/Dataset";
import {DatasetInfoResponse} from "../../../data/DatasetInfoDataSource";
import {Cell} from "../../../models/Cell";
import {Labeled} from "../../../models/Labeled";
import {GeneOntologyList} from "../model/GeneOntologyList";
import {GeneOntology, GeneOntologyGroup, geneOntologyName} from "../../../models/GeneOntology";
import {GeneOntologyDataSource, GOExpressionResponse} from "../../../data/GeneOntologyDataSource";
import {GeneOntologyUmapInfo} from "../model/GeneOntologyUmapInfo";
import {GeneOntologyModeRootInfo} from "../model/GeneOntologyModeRootInfo";
import {logEvent} from "firebase/analytics";
import {fbAnalytics} from "../../../analytics/AnalyticsScreenLogger";
import {debounce} from "lodash";
import {logMessage} from "../../../reportWebVitals";

const equal = require('deep-equal');

export interface GeneOntologyFeatureState {
    readonly geneList: GeneOntologyList | null,
    readonly loadingGeneInfo: boolean
    readonly selectedColorMap: ColorMap | null,
    readonly geneExpression: GOExpressionResponse | null
    readonly currentMaxExpression: number
    readonly maxExpressionTypedValue: number
    readonly currentUmap: Umap | null
    readonly hoveredCluster: number | null
    readonly labeledMap: RootSectionColorMap | null
    readonly currentGeneOntologyGroup: GeneOntologyGroup | null
    readonly geneOntologyGroups: Array<GeneOntologyGroup> | null

    readonly colorMaps: ColorMap[] | null,
    readonly labeled: Labeled[] | null,
    readonly cells: Cell[] | null
    readonly currentDataset: Dataset | null
    readonly datasetInfo: DatasetInfoResponse | null
}

export class GeneOntologyFeature {

    private resetSelectedClusterPromise: CancelablePromise<unknown> | null = null
    geneDataSource: GeneOntologyDataSource = new GeneOntologyDataSource()
    private currentState: GeneOntologyFeatureState
    private readonly stateChanged: Array<(state: GeneOntologyFeatureState) => void> = new Array<(state: GeneOntologyFeatureState) => void>()

    private updateExpression: (expression: number) => void
    private canExecuteDebounce: boolean = true

    constructor(appFeature: AppFeature) {
        this.addStateChangeListener = this.addStateChangeListener.bind(this)
        this.removeStateChangeListener = this.removeStateChangeListener.bind(this)
        this.loadInfo = this.loadInfo.bind(this)
        this.goGroupChanged = this.goGroupChanged.bind(this)
        this.geneChanged = this.geneChanged.bind(this)
        this.geneInputChanged = this.geneInputChanged.bind(this)
        this.colorMapChanged = this.colorMapChanged.bind(this)
        this.hoveredClusterChanged = this.hoveredClusterChanged.bind(this)
        this.appStateChanged = this.appStateChanged.bind(this)
        this.provideExpression = this.provideExpression.bind(this)

        this.expressionChanged = this.expressionChanged.bind(this)
        this.innerExpressionChanged = this.innerExpressionChanged.bind(this)
        this.updateExpression = debounce(this.innerDelayedExpressionChanged, 1000);

        this.currentState = {
            geneList: null,
            loadingGeneInfo: true,
            selectedColorMap: null,
            geneExpression: null,
            currentMaxExpression: 0,
            maxExpressionTypedValue: 0,
            currentUmap: null,
            hoveredCluster: null,
            labeledMap: null,
            geneOntologyGroups: null,
            currentGeneOntologyGroup: null,

            colorMaps: appFeature.currentState.appContent?.colorMaps ?? null,
            labeled: appFeature.currentState.labeled,
            cells: appFeature.currentState.cells,
            currentDataset: appFeature.currentState.currentDataset,
            datasetInfo: appFeature.currentState.datasetInfo,
        }

        appFeature.addStateChangeListener(state => {
            this.appStateChanged(state)
        })
        this.loadInfo()
    }

    public loadInfo() {
        this.geneDataSource.loadGOGroups()
            .then(r => {
                this.featureStateChanged({
                        ...this.currentState,
                        geneOntologyGroups: r,
                        currentGeneOntologyGroup: r[0],
                    },
                    "loadInfo"
                )
            })
    }

    public goGroupChanged(goGroup: GeneOntologyGroup) {
        if (this.currentState.currentGeneOntologyGroup === goGroup) {
            return
        }

        this.featureStateChanged({
                ...this.currentState,
                loadingGeneInfo: true,
                currentGeneOntologyGroup: goGroup,
            },
            "goGroupChanged"
        )

        this.geneDataSource.loadDefault(
            {
                datasetId: this.currentState.currentDataset?.id!!,
                geneOntologyId: goGroup.id,
            }
        )
            .then(r => {
                this.geneChanged(r)
            })
    }

    private appStateChanged(state: AppFeatureState) {
        if (state.datasetInfo && this.currentState.datasetInfo !== state.datasetInfo) {
            this.geneChanged(state.datasetInfo.defaultGeneOntology)
        }

        const colorMaps = state.appContent?.colorMaps ?? []
        const colorMap = colorMaps.find(value => value === this.currentState.selectedColorMap);
        if (!colorMap) {
            this.colorMapChanged(colorMaps[0] ?? null)
        }

        let newState =
            {
                ...this.currentState,

                colorMaps: state.appContent?.colorMaps ?? null,
                labeled: state.labeled,
                cells: state.cells,
                currentDataset: state.currentDataset,
                datasetInfo: state.datasetInfo,
            }

        this.featureStateChanged(newState, "appStateChanged")
    }

    public addStateChangeListener(listener: (state: GeneOntologyFeatureState) => void) {
        this.stateChanged.push(listener)
        listener(this.currentState)
    }

    public removeStateChangeListener(listener: (state: GeneOntologyFeatureState) => void) {
        const index = this.stateChanged.indexOf(listener, 0);
        if (index > -1) {
            this.stateChanged.splice(index, 1);
        }
    }


    public geneChanged(gene: GeneOntology) {
        let currentDataSet = this.currentState.currentDataset

        if (currentDataSet == null) {
            console.error("DataSet is null when try to update gene")
            return
        }

        if (this.currentState.geneList?.selectedGene === gene) {
            return;
        }

        if (this.currentState.geneList?.selectedGene) {
            logEvent(
                fbAnalytics,
                'select_gene_ontology_content',
                {
                    item_id: gene.geneOntologyId,
                    content_type: "gene_ontology",
                }
            )
        }

        this.featureStateChanged(
            {
                ...this.currentState,
                loadingGeneInfo: true,
            },
            "geneChanged"
        )

        this.geneDataSource
            .loadGOCellExpression({
                    geneOntologyId: gene.id,
                    datasetId: currentDataSet.id
                }
            )
            .then(r => {
                    this.featureStateChanged(
                        {
                            ...this.currentState,
                            geneExpression: r,
                            loadingGeneInfo: false,
                            geneList: {
                                input: geneOntologyName(gene),
                                isLoading: false,
                                searchedGene: [gene],
                                selectedGene: gene,
                                nonNullCells: r.nonNullCells,
                                cellsNumber: r.cellExpression.length,
                            },
                            currentMaxExpression: roundExpression(r.maxClusterExpression),
                            maxExpressionTypedValue: roundExpression(r.maxClusterExpression),
                        },
                        "loadGeneExpression"
                    )
                }
            )
    }

    public geneInputChanged(input: string) {
        let currentGeneList = this.currentState.geneList
        if (currentGeneList == null) {
            return
        }
        this.featureStateChanged(
            {
                ...this.currentState,
                geneList: {
                    ...currentGeneList,
                    isLoading: true,
                    input: input,
                    searchedGene: new Array<GeneOntology>()
                }
            },
            "geneInputChanged"
        )

        this.geneDataSource
            .searchGO(
                input,
                this.currentState.currentDataset?.id!!,
                this.currentState.currentGeneOntologyGroup?.id!!
            )
            .then(r => {
                    let geneList = this.currentState.geneList;
                    if (geneList?.input === input) {
                        this.featureStateChanged(
                            {
                                ...this.currentState,
                                geneList: {
                                    ...geneList,
                                    isLoading: false,
                                    searchedGene: r
                                }
                            },
                            "search"
                        )
                    }
                }
            )
    }

    public colorMapChanged(colorMap: ColorMap) {
        this.featureStateChanged({
                ...this.currentState,
                selectedColorMap: colorMap,
            },
            "colorMapChanged"
        )
    }

    public hoveredClusterChanged(cluster: number | null) {
        this.resetSelectedClusterPromise?.cancel()
        if (cluster != null) {
            this.resetSelectedClusterPromise = timeout(1500)
            this.resetSelectedClusterPromise
                ?.promise
                ?.then(() => {
                    this.hoveredClusterChanged(null)
                })
        }
        this.featureStateChanged({
                ...this.currentState,
                hoveredCluster: cluster,
            },
            "hoveredClusterChanged"
        )
    }

    public expressionChanged(maxExpression: string, force: boolean) {
        const expression = this.provideExpression(maxExpression)

        if (force) {
            this.canExecuteDebounce = false
            this.innerExpressionChanged(expression)
        } else {
            this.canExecuteDebounce = true
            this.featureStateChanged({
                    ...this.currentState,
                    maxExpressionTypedValue: expression,
                },
                "expressionChanged"
            )

            this.updateExpression(expression)
        }
    }

    private innerDelayedExpressionChanged(maxExpression: number) {
        if (this.canExecuteDebounce) {
            this.innerExpressionChanged(maxExpression)
        }
    }

    private innerExpressionChanged(maxExpression: number) {
        this.featureStateChanged({
                ...this.currentState,
                currentMaxExpression: maxExpression,
            },
            "expressionChanged"
        )
    }

    private provideExpression(maxExpression: string) {
        if (maxExpression === "") {
            return roundExpression(this.currentState.geneExpression?.maxClusterExpression)
        } else {
            return Math.max(0, roundExpression(parseFloat(maxExpression)))
        }
    }

    private featureStateChanged(featureState: GeneOntologyFeatureState, log: string) {
        logMessage(log, "featureStateChanged:", featureState)
        this.regenerateUmapIfNeeded(featureState, this.currentState)
        this.regenerateLabeledMapIfNeeded(featureState, this.currentState)

        if (!equal(featureState, this.currentState)) {
            this.stateChanged.forEach(l => l(featureState))
        }

        this.currentState = featureState
    }


    private regenerateUmapIfNeeded(
        newFeatureState: GeneOntologyFeatureState,
        currentFeatureState: GeneOntologyFeatureState
    ): boolean {
        const prevUmapInfo: UmapInfo = umapInfo(currentFeatureState)
        const newUmapInfo: UmapInfo = umapInfo(newFeatureState)

        if (!equal(prevUmapInfo, newUmapInfo)) {
            logMessage("regenerateUmapIfNeeded", newFeatureState)
            generateUmap(newUmapInfo)
                .then(umap => {
                    this.featureStateChanged(
                        {
                            ...this.currentState,
                            currentUmap: umap
                        },
                        "Umap.Generated"
                    )
                })

            return true
        }

        return false
    }

    private regenerateLabeledMapIfNeeded(
        newFeatureState: GeneOntologyFeatureState,
        currentFeatureState: GeneOntologyFeatureState
    ) {
        const prevRootInfo: RootInfo = rootInfo(currentFeatureState)
        const newRootInfo: RootInfo = rootInfo(newFeatureState)

        if (!equal(prevRootInfo, newRootInfo)) {
            logMessage("regenerateLabeledMapIfNeeded", newFeatureState)
            provideLabeledMap(newRootInfo)
                .then(map => {
                    this.featureStateChanged(
                        {
                            ...this.currentState,
                            labeledMap: map
                        },
                        "LabeledMap.Generated"
                    )
                })
        }
    }
}

function rootInfo(state: GeneOntologyFeatureState): GeneOntologyModeRootInfo {
    return {
        mode: "gene_ontology",
        currentColorMap: state.selectedColorMap,
        geneExpression: state.geneExpression,
        currentMaxExpression: state.currentMaxExpression,
        rootImage: state.datasetInfo?.rootImage,
        coveredCluster: state.hoveredCluster,
    }
}

function umapInfo(state: GeneOntologyFeatureState): GeneOntologyUmapInfo {
    return {
        mode: "gene_ontology",
        colorMap: state.selectedColorMap,
        geneExpression: state.geneExpression,
        datasetInfo: state.datasetInfo,
        maxExpression: state.currentMaxExpression,
        cells: state.cells,
    }
}