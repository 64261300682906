import {Component} from "react";
import {RootImageMode} from "../../models/AppContent";
import './RootAtlasComponent.css';
import {DatasetSelect} from "../DatasetSelect";
import {AppFeature} from "./data/AppFeature";


import {
    alpha,
    AppBar,
    Box,
    CircularProgress,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    styled,
    Toolbar
} from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import {theme} from "../../index";
import {GeneHeaderComponent} from "../gene/GeneHeaderComponent";
import {GeneFeature} from "../gene/data/GeneFeature";
import {ClusterFeature} from "../cluster/data/ClusterFeature";
import {AnnotationHeaderComponent} from "../cluster/AnnotationHeaderComponent";
import {GeneContentComponent} from "../gene/GeneContentComponent";
import {AnnotationContentComponent} from "../cluster/AnnotationContentComponent";
import {Dataset} from "../../models/Dataset";
import {GeneOntologyHeaderComponent} from "../gene_ontology/GeneOntologyHeaderComponent";
import {GeneOntologyFeature} from "../gene_ontology/data/GeneOntologyFeature";
import {GeneOntologyContentComponent} from "../gene_ontology/GeneOntologyContentComponent";
import SavingIcon from "../../view.icons/SavingIcon";
import GeneExpressionIcon from "../../view.icons/GeneExpressionIcon";
import AnnotationIcon from "../../view.icons/AnnotationIcon";
import GeneOntologyIcon from "../../view.icons/GeneOntologyIcon";
import {ALLOW_GO} from "../../data/Network";
import {logMessage} from "../../reportWebVitals";

const atlasLogo = `${process.env.PUBLIC_URL}/images/startPage_atlasLogo.svg`;

interface RootAtlasProps {
    readonly onStartPageOpenClick: () => void
    readonly onHelpClick: () => void
    readonly onDonateClick: () => void
}

export interface RootAtlasState {
    readonly datasets: Dataset[] | null
    readonly isLoading: boolean
    readonly currentMode: RootImageMode
    readonly selectedDataset: Dataset | null
}

const ColoredListItem = styled(List)(({theme}) => ({
    // selected and (selected + hover) states
    '&& .Mui-selected, && .Mui-selected:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.25),
        '&, & .MuiListItemIcon-root': {
            // color: theme.palette.secondary.main,
        },
    },
    // hover states
    '& .MuiListItemButton-root:hover': {
        backgroundColor: alpha(theme.palette.secondary.light, 0.25),
        '&, & .MuiListItemIcon-root': {
            // color: 'yellow',
        },
    },
}));


export interface State {
    readonly rootAtlasState: RootAtlasState
    readonly drawerOpen: boolean
}

export const DrawerHeader = styled("div")(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "96px",
}));

export class RootAtlasComponent extends Component<RootAtlasProps, State> {

    appFeature: AppFeature =
        new AppFeature(
            state => {
                logMessage("RootAtlas", "AppFeatureStateChanged", state)
                this.setState(
                    {
                        drawerOpen: false,
                        rootAtlasState: state
                    }
                )
            }
        )

    geneFeature: GeneFeature = new GeneFeature(this.appFeature)
    clusterFeature: ClusterFeature = new ClusterFeature(this.appFeature)
    geneOntologyFeature: GeneOntologyFeature = new GeneOntologyFeature(this.appFeature)

    selectMode = (mode: RootImageMode) => {
        this.appFeature.onModeChanged(mode)
    }

    handleDrawerOpen = () => {
        if (!this.state.drawerOpen) {
            this.setState(
                {
                    ...this.state,
                    drawerOpen: true
                }
            )
        }
    };

    handleDrawerClose = () => {
        this.setState(
            {
                ...this.state,
                drawerOpen: false
            }
        )
    };

    constructor(props: RootAtlasProps) {
        super(props);
        this.state = {
            drawerOpen: false,
            rootAtlasState: this.appFeature.appState()
        }
        this.modeActions = this.modeActions.bind(this)
        this.drawer = this.drawer.bind(this)
        RootAtlasComponent.loading = RootAtlasComponent.loading.bind(this)
    }

    componentDidMount() {
        this.appFeature.loadInfo()
    }

    render() {
        logMessage("RootAtlas", "RootAtlasRender:", this.state)
        const datasets = this.state.rootAtlasState.datasets
        const selectedDataset = this.state.rootAtlasState.selectedDataset;

        let content
        let topContent = null
        let loadingGrid = null

        if (selectedDataset == null || this.state.rootAtlasState.isLoading || datasets == null) {
            content = RootAtlasComponent.loading()
        } else {
            topContent = this.modeActions(selectedDataset, datasets)
            content = this.snapShotContent(this.state.rootAtlasState.currentMode)
        }

        return (
            <Stack sx={{position: "relative", height: "100%"}}>
                <Box width={"100%"} sx={{width: "100%"}}>
                    <AppBar
                        position="fixed"
                        sx={{zIndex: theme.zIndex.drawer + 1, bgcolor: theme.palette.primary.light}}
                    >
                        <Toolbar sx={{height: 90, ml: 0}}>
                            <Box>
                                <Link onClick={this.props.onStartPageOpenClick}>
                                    <img src={atlasLogo} alt="Atlas Logo" height="32px"/>
                                </Link>
                            </Box>
                            <Box sx={{marginLeft: 4}}/>
                            {topContent}
                            <Box sx={{flexGrow: 1}}/>
                            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                                <IconButton
                                    size="large"
                                    onClick={this.props.onStartPageOpenClick}
                                    color="inherit">
                                    <HomeIcon/>
                                </IconButton>
                            </Box>
                            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                                <IconButton
                                    size="large"
                                    onClick={this.props.onDonateClick}
                                    color="inherit">
                                    <SavingIcon/>
                                </IconButton>
                            </Box>
                            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                                <IconButton
                                    size="large"
                                    onClick={this.props.onHelpClick}
                                    color="inherit">
                                    <HelpIcon/>
                                </IconButton>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Box display={"flex"} mt={'96px'}>
                    {content}
                    {loadingGrid}
                </Box>
            </Stack>
        );
    }

    private drawer(currentMode: RootImageMode) {
        return <Drawer
            variant="permanent"
            open={this.state.drawerOpen}
            onMouseOver={this.handleDrawerOpen}
            onMouseLeave={this.handleDrawerClose}>
            <DrawerHeader/>
            <ColoredListItem>
                {this.drawerItem('main', currentMode)}
                {this.drawerItem('cluster', currentMode)}
                {ALLOW_GO && this.drawerItem('gene_ontology', currentMode)}
            </ColoredListItem>
        </Drawer>
    }

    private drawerItem(mode: RootImageMode, selectedMode: RootImageMode) {
        let name
        let icon
        switch (mode) {
            case "main":
                icon = <GeneExpressionIcon/>
                name = "Gene Expression"
                break;
            case "cluster":
                icon = <AnnotationIcon/>
                name = "Annotation"
                break;
            case "gene_ontology":
                icon = <GeneOntologyIcon/>
                name = "Gene Ontology"
                break;
        }
        const click = () => {
            this.selectMode(mode)
        }
        return <ListItem
            key={mode}
            disablePadding
            sx={{display: "block"}}
            onClick={click}>
            <ListItemButton
                sx={{
                    minHeight: 12,
                    justifyContent: this.state.drawerOpen ? "initial" : "center",
                    px: 2.5
                }}
                selected={selectedMode === mode}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: this.state.drawerOpen ? 3 : "auto",
                        paddingTop: !this.state.drawerOpen ? "4px" : "0px",
                        paddingBottom: !this.state.drawerOpen ? "4px" : "0px",
                        justifyContent: "center"
                    }}
                >
                    {icon}
                </ListItemIcon>
                {this.state.drawerOpen ? <ListItemText primary={name}/> : undefined}
            </ListItemButton>
        </ListItem>;
    }

    private static loading() {
        return <Box sx={{
            position: 'absolute',
            zIndex: 'modal',
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }} pr={2} pl={2}>
            <CircularProgress/>
        </Box>;
    }

    private modeActions(
        selectedDataset: Dataset | null,
        datasets: Dataset[]
    ) {
        let modeComponents;
        switch (this.state.rootAtlasState.currentMode) {
            case "main":
                modeComponents = <GeneHeaderComponent geneFeature={this.geneFeature}/>
                break;
            case "cluster":
                modeComponents = <AnnotationHeaderComponent clusterFeature={this.clusterFeature}/>
                break;
            case 'gene_ontology':
                modeComponents = <GeneOntologyHeaderComponent geneOntologyFeature={this.geneOntologyFeature}/>
                break;
        }

        return <Stack direction="row" spacing={2}>
            <DatasetSelect
                selectedOption={selectedDataset ?? undefined}
                options={datasets}
                optionChanged={this.appFeature.datasetChanged}
            />
            {modeComponents}
        </Stack>;
    }

    private snapShotContent(mode: RootImageMode) {
        let content
        switch (mode) {
            case "main":
                content = <GeneContentComponent geneFeature={this.geneFeature}/>
                break;
            case "cluster":
                content = <AnnotationContentComponent clusterFeature={this.clusterFeature}/>
                break;
            case "gene_ontology":
                content = <GeneOntologyContentComponent geneOntologyFeature={this.geneOntologyFeature}/>
                break;
        }

        return <Box sx={{width: `100%`}}>
            {this.drawer(this.state.rootAtlasState.currentMode)}
            <Box sx={{pl: '80px', pr: '20px', display: 'flex', justifyContent: 'center'}}>
                {content}
            </Box>
        </Box>
    }
}