import {Component} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Link,
    responsiveFontSizes,
    Stack,
    ThemeProvider,
    Typography
} from "@mui/material";
import './startPage.css'
import {DONATION_URL} from "../../data/Network";
import {theme} from "../../index";

const atlasLogo = `${process.env.PUBLIC_URL}/images/startPage_atlasLogo.svg`;
const rootImage = `${process.env.PUBLIC_URL}/images/startPage_root.svg`;

interface StartPageProps {
    readonly onRootAtlasClicked: () => void
}


class StartPageComponent extends Component<StartPageProps> {
    responsiveTheme = responsiveFontSizes(theme, {factor: 4});

    render() {
        return (
            <ThemeProvider theme={this.responsiveTheme}>
                <Card
                    sx={
                        {
                            width: '60%',
                            paddingTop: 2,
                            paddingLeft: 4,
                            paddingRight: 4,
                            maxWidth: '100em',
                            ml: '20%',
                            mr: '20%',
                            borderRadius: 8,
                        }
                    }
                    elevation={6}>
                    <CardContent>
                        <Grid container spacing={4}
                              alignItems={"stretch"}
                              height={"auto"}>
                            <Grid item xs={4} justifyContent={"center"} display={"flex"}>
                                <img height={'auto'} width={"100%"} src={rootImage} alt={"Root Cell"}/>
                            </Grid>

                            <Grid item xs={8}>
                                <Stack direction="column"
                                       sx={{
                                           height: "100%"
                                       }}
                                       justifyContent="space-between"
                                       alignItems="center">
                                    <Stack
                                        direction={"column"}
                                        alignItems="center"
                                        mt={2}
                                        justifyContent={"center"}
                                        height={'100%'}>
                                        <Typography color={"darkgray"} textAlign={"justify"} variant={"h5"}>
                                            The advent of single-cell technologies is revolutionizing the details of
                                            whole-transcriptome snapshots from a tissue to a cell, yet we are still at
                                            the
                                            beginning of unlocking the full potential of single-cell data. Striving to
                                            push
                                            the
                                            boundaries of plant single-cell biology, here we present you our
                                        </Typography>
                                        <Box
                                            sx={{
                                                justifyContent: "center",
                                                display: "flex",
                                                mt: 2
                                            }}>
                                            <Button
                                                variant="contained"
                                                color={"primary"}
                                                size={"medium"}
                                                sx={{
                                                    backgroundColor: "#efefef",
                                                    width: "fit-content"
                                                }}
                                                onClick={this.props.onRootAtlasClicked}
                                            >
                                                <div style={{
                                                    justifyContent: "center",
                                                    width: "159px",
                                                }}>
                                                    <img src={atlasLogo} alt="Root Atlas"
                                                         style={{justifyContent: "center"}}/>
                                                </div>
                                            </Button>
                                        </Box>
                                        <Typography mt={2} variant={"h5"} textAlign={"justify"} color={"darkgray"}>
                                            where available public Arabidopsis root single-cell datasets are integrated
                                            into
                                            a unified reference atlas with unprecedented spatial resolution - for
                                            everyone
                                            trying to understand how different (sub)types of root cells are wired at the
                                            level of gene expression.
                                        </Typography>

                                        <Typography mt={2} variant={"h5"} color={"darkgray"}>
                                            All <Link
                                            target="_blank"
                                            href={DONATION_URL}>
                                            donations
                                        </Link> support the growth of our project
                                        </Typography>
                                    </Stack>
                                    <Typography mt={2} variant={"h6"} color={"darkgray"}>
                                        RootCellAtlas initiative 2022
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </ThemeProvider>
        )
    }
}

export default StartPageComponent