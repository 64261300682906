import React, {useEffect, useRef} from 'react';
import {RootImageData} from "./model/RootImageData";
import {ColorRgb} from "../../models/ColorMap";
import {LabeledColor} from "../../models/RootImage";
import {Box, Stack, Typography} from "@mui/material";
import {logMessage} from "../../reportWebVitals";

interface RootImageCanvasProps {
    readonly rootImageData: RootImageData
    readonly labeledMap: Array<LabeledColor>
    readonly scale: number
    readonly title: string
}

const RootImageCanvas = (props: RootImageCanvasProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            rootImage(canvas, props.rootImageData, props.labeledMap, props.scale)
                .then(imageData => {
                        if (canvas && imageData) {
                            let context = canvas.getContext("2d")
                            if (context) {
                                context.putImageData(imageData, 0, 0)
                            }
                        }
                    }
                )
        }
    })

    return (
        <Stack textAlign={"center"} key={props.title} justifyContent={"center"} spacing={1} sx={{mt: `${props.title ? '0px' : '24px'}`}}>
            <Typography variant='h5' whiteSpace={"nowrap"}>{props.title}</Typography>
            <Box>
                <canvas
                    ref={canvasRef}
                    height={props.rootImageData.height * props.scale}
                    width={props.rootImageData.width * props.scale}/>
            </Box>
        </Stack>);
};

async function rootImage(
    canvas: HTMLCanvasElement,
    rootImageData: RootImageData,
    labeledMap: Array<LabeledColor>,
    scale: number,
): Promise<ImageData | null> {
    const matrix: Array<Array<number>> = rootImageData.data
    const context = canvas.getContext('2d', {willReadFrequently: true});
    if (context) {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        let id = context.getImageData(0, 0, rootImageData.width, rootImageData.height);
        let pixels = id.data;

        matrix.forEach((item, x) => {
            item.forEach((num, y) => {
                    let color
                    if (num === 0) {
                        color = blackColorRgb
                    } else {
                        color = labeledMap.find(e => e.labeledNumber === num)?.colorRgb ?? defaultColorRgb
                    }
                    let off = (x * id.width + y) * 4;
                    pixels[off] = color.red;
                    pixels[off + 1] = color.green;
                    pixels[off + 2] = color.blue;
                    pixels[off + 3] = color.alpha;
                }
            )

        })
        const resizeWidth = rootImageData.width * scale
        const resizeHeight = rootImageData.height * scale
        const resizedImageData =
            await window.createImageBitmap(id, 0, 0, rootImageData.width, rootImageData.height, {
                resizeWidth, resizeHeight
            })
        if (resizedImageData) {
            context.drawImage(resizedImageData, 0, 0)
        }
    }

    return null
}

export async function resizeImageData(
    imageData: ImageData,
    resizeWidth: number,
    resizeHeight: number,
): Promise<ImageData | null> {
    logMessage(resizeWidth.toString().concat("x", resizeHeight.toString()))
    const ibm = await window.createImageBitmap(imageData, 0, 0, imageData.width, imageData.height, {
        resizeWidth, resizeHeight
    })
    const canvas = document.createElement('canvas')
    canvas.width = resizeWidth
    canvas.height = resizeHeight
    const ctx = canvas.getContext('2d')
    logMessage(resizeWidth / imageData.width)
    if (ctx) {
        ctx.scale(resizeWidth / imageData.width, resizeHeight / imageData.height)
        ctx.drawImage(ibm, 0, 0)
        return ctx.getImageData(0, 0, resizeWidth, resizeHeight)
    }
    return null
}

export const defaultColorRgb: ColorRgb =
    {
        red: 255,
        green: 255,
        blue: 255,
        alpha: 255,
    }

const blackColorRgb: ColorRgb =
    {
        red: 0,
        green: 0,
        blue: 0,
        alpha: 255,
    }

export default RootImageCanvas;