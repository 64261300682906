import {GeneExpressionResponse} from "../../../data/GeneDataSource";
import {LabeledColor, RootSection} from "../../../models/RootImage";
import {ColorMap, ColorRgb} from "../../../models/ColorMap";
import {Cluster} from "../../../models/Cluster";
import {GeneModeRootInfo} from "../../gene/model/GeneModeRootInfo";
import {ClusterModeRootInfo} from "../../cluster/model/ClusterModeRootInfo";
import {GeneOntologyModeRootInfo} from "../../gene_ontology/model/GeneOntologyModeRootInfo";
import {GOExpressionResponse} from "../../../data/GeneOntologyDataSource";

export declare type RootInfo = GeneModeRootInfo | ClusterModeRootInfo | GeneOntologyModeRootInfo
export declare type RootSectionColorMap = Map<RootSection, Array<LabeledColor>>

export async function provideLabeledMap(rootInfo: RootInfo): Promise<RootSectionColorMap | null> {
    const root = rootInfo as GeneModeRootInfo
    const cluster = rootInfo as ClusterModeRootInfo
    const geneOntology = rootInfo as GeneOntologyModeRootInfo

    switch (rootInfo.mode) {
        case "main":
            return await provideRootLabeledMap(root)
        case "cluster":
            return await provideClusterLabeledMap(cluster)
        case "gene_ontology":
            return await provideGOLabeledMap(geneOntology)

    }

    return null
}

async function provideClusterLabeledMap(clusterInfo: ClusterModeRootInfo): Promise<RootSectionColorMap | null> {
    const colorMap = clusterInfo.currentColorMap;
    const rootImage = clusterInfo.rootImage;

    if (colorMap && rootImage) {
        return new Map(
            clusterInfo
                .rootImage
                .rootSections
                .map(e => [e, provideClusterLabeledColor(e, clusterInfo.clusters, clusterInfo.selectedClusters, colorMap)])
        )
    }

    return null
}

function provideClusterLabeledColor(
    section: RootSection,
    clusters: Cluster[],
    selectedCluster: Cluster[],
    currentColorMap: ColorMap,
): Array<LabeledColor> {
    let result = new Array<LabeledColor>()
    clusters
        .forEach(cluster => {
            let labeledCluster = section.labeledClusters.find(e => e.clusterId === cluster.id)
            if (labeledCluster) {
                let colorRgb: ColorRgb
                if (selectedCluster.find(e => e.id === cluster.id)) {
                    colorRgb = currentColorMap.colorMap[currentColorMap.colorMap.length - 1]
                } else {
                    colorRgb = currentColorMap.colorMap[0]
                }
                labeledCluster.labeledNumbers.forEach(num => {
                    result.push(
                        {
                            labeledNumber: num,
                            colorRgb: colorRgb
                        }
                    )
                })
            }
        })

    return result
}

async function provideGOLabeledMap(rootInfo: GeneOntologyModeRootInfo): Promise<RootSectionColorMap | null> {
    const geneExpression = rootInfo.geneExpression;
    const currentMaxExpression = rootInfo.currentMaxExpression;
    const currentColorMap = rootInfo.currentColorMap;

    if (geneExpression !== null && rootInfo.rootImage && currentColorMap && currentMaxExpression !== null) {
        return new Map(
            rootInfo.rootImage.rootSections
                .map(e => [e, provideGOLabeledColor(e, geneExpression, currentMaxExpression, currentColorMap, rootInfo.coveredCluster)])
        )
    }

    return null
}


async function provideRootLabeledMap(rootInfo: GeneModeRootInfo): Promise<RootSectionColorMap | null> {
    const geneExpression = rootInfo.geneExpression;
    const currentMaxExpression = rootInfo.currentMaxExpression;
    const currentColorMap = rootInfo.currentColorMap;

    if (geneExpression !== null && rootInfo.rootImage && currentColorMap && currentMaxExpression !== null) {
        return new Map(
            rootInfo.rootImage.rootSections
                .map(e => [e, provideRootLabeledColor(e, geneExpression, currentMaxExpression, currentColorMap, rootInfo.coveredCluster)])
        )
    }

    return null
}

function provideRootLabeledColor(
    section: RootSection,
    geneExpression: GeneExpressionResponse,
    currentMaxExpression: number,
    currentColorMap: ColorMap,
    selectedCluster: number | null
): Array<LabeledColor> {
    let result = new Array<LabeledColor>()
    geneExpression.clustersExpression
        .forEach(clusterExpression => {
            let labeledCluster = section.labeledClusters.find(e => e.clusterId === clusterExpression.cluster)
            if (labeledCluster) {
                let rgbIndex =
                    Math.min(
                        currentMaxExpression === 0 ? 0 : Math.floor((clusterExpression.expression * (currentColorMap.colorMap.length - 1)) / currentMaxExpression),
                        currentColorMap.colorMap.length - 1
                    )

                let alpha: number
                if (selectedCluster) {
                    if (labeledCluster.clusterId === selectedCluster) {
                        alpha = 255
                    } else {
                        alpha = 15
                    }
                } else {
                    alpha = 255
                }
                labeledCluster.labeledNumbers.forEach(num => {
                    result.push(
                        {
                            labeledNumber: num,
                            colorRgb: {
                                ...currentColorMap.colorMap[rgbIndex],
                                alpha: alpha,
                            }
                        }
                    )
                })
            }
        })

    return result
}

function provideGOLabeledColor(
    section: RootSection,
    geneExpression: GOExpressionResponse,
    currentMaxExpression: number,
    currentColorMap: ColorMap,
    selectedCluster: number | null
): Array<LabeledColor> {
    let result = new Array<LabeledColor>()
    geneExpression.clustersExpression
        .forEach(clusterExpression => {
            let labeledCluster = section.labeledClusters.find(e => e.clusterId === clusterExpression.cluster)
            if (labeledCluster) {
                let rgbIndex =
                    Math.min(
                        currentMaxExpression === 0 ? 0 : Math.floor((clusterExpression.expression * (currentColorMap.colorMap.length - 1)) / currentMaxExpression),
                        currentColorMap.colorMap.length - 1
                    )

                let alpha: number
                if (selectedCluster) {
                    if (labeledCluster.clusterId === selectedCluster) {
                        alpha = 255
                    } else {
                        alpha = 15
                    }
                } else {
                    alpha = 255
                }
                labeledCluster.labeledNumbers.forEach(num => {
                    result.push(
                        {
                            labeledNumber: num,
                            colorRgb: {
                                ...currentColorMap.colorMap[rgbIndex],
                                alpha: alpha,
                            }
                        }
                    )
                })
            }
        })

    return result
}

export default provideLabeledMap