import {Component} from "react";
import Plot from "react-plotly.js";
import {ColorMap} from "../../models/ColorMap";
import {PlotData} from "plotly.js";
import {Box} from "@mui/material";

export interface RootImageColorBarProps {
    readonly colorMap: ColorMap
    readonly maxExpression: number
    readonly height: number
}

export class RootImageColorBar extends Component<RootImageColorBarProps> {

    render() {
        let colorRgbs = this.props
            .colorMap
            .colorMap;

        let data = colorRgbs
            .map(rgb => {
                return {
                    x: ['colorMap'],
                    y: [1 / colorRgbs.length * this.props.maxExpression],
                    type: 'bar',
                    marker: {
                        color: `rgba(${Math.floor(rgb.red)}, ${Math.floor(rgb.green)}, ${Math.floor(rgb.blue)}, 255)`,
                    },
                } as PlotData
            });

        return (
            <Box ml={'-60px'}>
                <Plot
                    config={{
                        displayModeBar: false,
                    }}
                    data={data}
                    layout={{
                        plot_bgcolor: 'rgba(0,0,0,0)',
                        paper_bgcolor: 'rgba(0,0,0,0)',
                        dragmode: false,
                        xaxis: {
                            visible: false,
                        },
                        yaxis: {
                            title: '',
                            side: 'right',
                            tickfont: {
                                color: 'black',
                                family: '"Roboto","Helvetica","Arial",sans-serif',
                                size: 16
                            },
                        },
                        showlegend: false,
                        margin: {'t': 0, 'b': 0, 'r': 60},
                        hovermode: false,
                        barmode: 'stack',
                        width: 160,
                        height: this.props.height
                    }}
                />
            </Box>
        )
    }
}
