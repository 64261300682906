import {Icon, IconProps} from "@mui/material";

const dnaStructure = `${process.env.PUBLIC_URL}/images/dna-structure.svg`;

export default function GeneExpressionIcon(props: IconProps) {
    return (
        <Icon
            {...props}>
            <img src={dnaStructure} alt={"donation"} style={{height: "100%"}}/>
        </Icon>
    );
}