import {CircularProgress, InputAdornment, TextField} from "@mui/material";
import {memo} from "react";

interface MaxExpressionValue {
    maxExpression: number | null
    maxExpressionTypedValue: number
    isLoading: boolean
    onChange: (value: string, forced: boolean) => void
}


const MaxExpressionValueComponent = (props: MaxExpressionValue) => {
    let inputProps;
    if (props.isLoading) {
        inputProps = {
            inputProps: {
                step: 0.01,
            },
            endAdornment: (
                <InputAdornment position='end'>
                    <CircularProgress size={'12px'}/>
                </InputAdornment>
            ),
        }
    } else {
        inputProps = {
            inputProps: {
                step: 0.01,
            },
        }
    }

    return (
        <TextField
            label="Max. Threshold"
            type="number"
            value={props.maxExpressionTypedValue}
            defaultValue={props.maxExpression?.toString() ?? ""}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    const target = e.target as HTMLTextAreaElement;
                    props.onChange(target.value, true)
                }
            }}
            disabled={props.isLoading}
            onChange={e => props.onChange(e.target.value, false)}
            InputProps={inputProps}
        />
    )
}

export default memo(MaxExpressionValueComponent)
