export interface GeneOntology {
    readonly id: number
    readonly geneOntologyId: string
    readonly label: string
    readonly geneOntologyGroup: number
}

export function geneOntologyName(gene: GeneOntology): string {
    let geneLabels = gene.label

    if (!geneLabels) {
        return gene.geneOntologyId
    }

    return gene.geneOntologyId.concat("/", geneLabels)
}

export interface GeneOntologyGroup {
    readonly id: number
    readonly geneOntologyGroupId: string
    readonly label: string
}