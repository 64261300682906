import {Component} from "react";
import {Box, Stack} from "@mui/material";
import GenSelect from "../GenSelectComponent";
import {ColorMapSelect} from "../ColorMapSelect";
import MaxExpressionValueComponent from "../MaxExpressionValue";
import {GeneFeature, GeneFeatureState} from "./data/GeneFeature";
import {GeneList} from "./model/GeneList";
import {ColorMap} from "../../models/ColorMap";

interface GeneHeaderProps {
    readonly geneFeature: GeneFeature
}

interface GeneHeaderState {
    readonly colorMaps: ColorMap[]
    readonly geneList: GeneList | null
    readonly loadingGeneInfo: boolean
    readonly selectedColorMap: ColorMap | undefined
    readonly maxClusterExpression: number
    readonly maxExpressionTypedValue: number
}

export class GeneHeaderComponent extends Component<GeneHeaderProps, GeneHeaderState> {

    geneFeature: GeneFeature
    listener: (state: GeneFeatureState) => void =
        state => {
            this.setState(
                {
                    geneList: state.geneList,
                    colorMaps: state.colorMaps ?? [],
                    loadingGeneInfo: state.loadingGeneInfo,
                    selectedColorMap: state.selectedColorMap ?? undefined,
                    maxClusterExpression: state.currentMaxExpression,
                    maxExpressionTypedValue: state.maxExpressionTypedValue,
                }
            )
        }

    constructor(props: GeneHeaderProps) {
        super(props);
        this.geneFeature = props.geneFeature

        this.state =
            {
                geneList: null,
                colorMaps: [],
                loadingGeneInfo: true,
                selectedColorMap: undefined,
                maxClusterExpression: 0,
                maxExpressionTypedValue: 0,
            }
    }

    componentDidMount() {
        this.geneFeature.addStateChangeListener(this.listener)
    }

    componentWillUnmount() {
        this.geneFeature.removeStateChangeListener(this.listener)
    }

    render() {
        const geneList = this.state.geneList;
        if (geneList == null) {
            return <Box/>
        }

        return <Stack direction="row" spacing={2}>
            <GenSelect
                geneChanged={this.geneFeature.geneChanged}
                geneInput={geneList.input}
                loadOptions={this.geneFeature.geneInputChanged}
                isLoading={this.state.loadingGeneInfo}
                optionIsLoading={geneList.isLoading}
                options={geneList.searchedGene}
                selectedOption={geneList.selectedGene}/>
            <ColorMapSelect
                selectedOption={this.state.selectedColorMap}
                options={this.state.colorMaps}
                optionChanged={this.geneFeature.colorMapChanged}
            />
            <MaxExpressionValueComponent
                maxExpressionTypedValue={this.state.maxExpressionTypedValue}
                isLoading={this.state.loadingGeneInfo}
                onChange={this.geneFeature.expressionChanged}
                maxExpression={this.state.maxClusterExpression}
            />
        </Stack>;
    }
}