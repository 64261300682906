import {Umap} from "../models/Umap";
import {Layout, PlotData} from "plotly.js";
import Plot from "react-plotly.js";
import React, {Component, memo, ReactElement, RefObject} from "react";
import {Cell} from "../models/Cell";
import {Box, Card, CardContent, CircularProgress} from "@mui/material";
import {ColorRgb} from "../models/ColorMap";
import DownloadImage from "./DownloadImage";
import {logMessage} from "../reportWebVitals";

const equal = require('deep-equal');

interface UmapProps {
    readonly umap: Umap | null
    readonly fileName: string
    readonly description: ReactElement
    readonly clusterHovered: (cluster: number | null) => void
}

interface UmapState {
    readonly data: PlotData[] | null
    readonly showDownloadImage: boolean
}

class UmapComponent extends Component<UmapProps, UmapState> {
    componentRef: RefObject<HTMLDivElement>

    constructor(props: UmapProps) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            data: null,
            showDownloadImage: false,
        }
    }

    componentDidMount() {
        const currentPlotInfo = providePlotInfo(this.state, this.props);
        this.setState({
            ...this.state,
            data: currentPlotInfo ? propsToData(currentPlotInfo) : null
        })
    }

    componentDidUpdate(prevProps: Readonly<UmapProps>, prevState: Readonly<UmapState>, snapshot?: any) {
        const oldPlotInfo = providePlotInfo(prevState, prevProps);
        const currentPlotInfo = providePlotInfo(this.state, this.props);
        if (!equal(oldPlotInfo, currentPlotInfo)) {
            this.setState({
                ...this.state,
                data: currentPlotInfo ? propsToData(currentPlotInfo) : null
            })
        }
    }

    render() {
        let content;
        let umap = this.props.umap;
        let data = this.state.data

        if (umap == null || data == null) {
            content = (
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center"
                     minHeight="100%">
                    <CircularProgress/>
                </Box>
            )
        } else {

            let layout = {
                margin: {'t': 60},
                height: 800,
                plot_bgcolor: 'rgba(0,0,0,0)',
                dragmode: false,
                xaxis: {
                    title: "UMAP_1",
                    zeroline: false,
                    tickfont: {
                        color: 'black',
                        family: '"Roboto","Helvetica","Arial",sans-serif',
                        size: 16
                    },
                    titlefont: {
                        color: 'black',
                        family: '"Roboto","Helvetica","Arial",sans-serif',
                        size: 16
                    }
                },
                yaxis: {
                    side: 'bottom',
                    title: "UMAP_2",
                    zeroline: false,
                    tickfont: {
                        color: 'black',
                        family: '"Roboto","Helvetica","Arial",sans-serif',
                        size: 16
                    },
                    titlefont: {
                        color: 'black',
                        family: '"Roboto","Helvetica","Arial",sans-serif',
                        size: 16
                    },
                },
                showlegend: false,
                hovermode: 'closest',

            } as Layout;
            content =
                <Plot
                    config={{
                        displayModeBar: false,
                        autosizable: true,
                        responsive: true,
                    }}
                    onButtonClicked={e => {
                        logMessage(e)
                    }
                    }
                    onDoubleClick={() => {
                        this.props.clusterHovered(null)
                    }
                    }
                    data={data}
                    onClick={e => {
                        logMessage(e)
                        const selectedCluster = (e.points[0].customdata as number) ?? null;
                        this.props.clusterHovered(selectedCluster)
                    }
                    }
                    style={{width: "100%", height: "100%"}}
                    useResizeHandler
                    layout={layout}/>
        }

        return (
            <React.Fragment>
                <Card
                    sx={{height: '100%', width: '100%'}}
                    onMouseEnter={() => {
                        this.setState({
                                ...this.state,
                                showDownloadImage: true
                            }
                        )
                    }}
                    onMouseLeave={() => {
                        this.setState({
                                ...this.state,
                                showDownloadImage: false
                            }
                        )
                    }}
                >
                    <CardContent sx={{height: '100%'}}>
                        <Box sx={{position: "relative"}}>
                            <DownloadImage
                                componentRef={this.componentRef}
                                fileName={this.props.fileName}
                                show={this.state.showDownloadImage && this.props.umap != null}/>
                            <Box ref={this.componentRef} paddingLeft={2} paddingRight={2}>
                                {content}
                                {this.props.description}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

interface PlotInfo {
    coloredPosition: Map<ColorRgb, Cell[]>,
    defaultColor: ColorRgb,
}

function providePlotInfo(state: UmapState | null, props: UmapProps): PlotInfo | null {
    let umap = props.umap
    if (umap) {
        return {
            coloredPosition: umap.coloredPosition,
            defaultColor: umap.colorMap.colorMap[0],
        }
    }

    return null
}

function propsToData(props: PlotInfo): PlotData[] {
    if (props.coloredPosition) {
        return Array.from(props.coloredPosition.entries())
            .map(value => {
                const color = value[0] ?? props.defaultColor
                const cells = value[1]

                let selectedCluster: Array<number | null>
                selectedCluster = cells.map(e => e.cluster)

                return {
                    x: cells.map(e => e.umapPoint.x),
                    y: cells.map(e => e.umapPoint.y),
                    mode: 'markers',
                    type: 'scattergl',
                    customdata: selectedCluster,
                    text: cells.map(e => e.clusterLabel),
                    hoverinfo: "text",
                    marker: {
                        size: 4,
                        color: `rgba(${color.red}, ${color.green}, ${color.blue}, ${color.alpha})`,
                    },
                } as PlotData
            })
    }
    return []
}

export default memo(
    UmapComponent,
    (prevProps, nextProps) => {
        return equal(prevProps.umap, nextProps.umap)
    }
);

