import {memo} from "react";
import SelectForm from "./SelectForm";
import {GeneOntologyGroup} from "../models/GeneOntology";

interface Props {
    options: GeneOntologyGroup[]
    optionChanged: (newOption: GeneOntologyGroup) => void,
    selectedOption: GeneOntologyGroup | undefined
}

export const GeneOntologyGroupSelect = memo(
    (props: Props) => {
        return (
            <SelectForm
                label={"Select GO vocabulary"}
                optional={false}
                minWidth={"150px"}
                options={props.options}
                optionChanged={e => props.optionChanged(e as GeneOntologyGroup)}
                selectedOption={props.selectedOption}
            />
        )
    }
)