import React, {Component} from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Link,
    Stack,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {DEPARTMENT_OF_PLANT, DONATION_URL, RADBOUD_UNIVERSITY, RIBES} from "../../data/Network";

const atlasLogo = `${process.env.PUBLIC_URL}/images/startPage_atlasLogo.svg`;

interface AboutProps {
    readonly isShow: boolean
    readonly onClose: () => void
}

class AboutComponent extends Component<AboutProps> {

    render() {
        return <Dialog
            open={this.props.isShow}
            scroll={"paper"}
            maxWidth="lg"
            onClose={this.props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{
                padding: "10px",
            }}
        >
            <DialogTitle
                style={
                    {
                        paddingLeft: "40px",
                        paddingRight: "40px",
                    }
                }
                id="alert-dialog-title">
                <Stack direction={"row"} spacing={2} alignItems={"baseline"}>
                    <img src={atlasLogo} alt="Atlas Logo" height={24}/>

                    <Typography variant={"h5"} fontWeight={"bold"}>
                        Information
                    </Typography>

                    <Box flexGrow={1}/>
                    <IconButton onClick={this.props.onClose}>
                        <CloseIcon/>
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent
                style={
                    {
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        paddingBottom: "40px"
                    }
                }>
                <DialogContentText id="alert-dialog-description" textAlign={"justify"}>
                    Root Cell Atlas changes the way to access single-cell transcriptomics data in a user-friendly
                    manner.
                    <br/>
                    <br/>
                    <b>Functionality:</b> The web service visualizes the single-cell gene expression data taken from
                    available
                    datasets on a realistic root layout. The interactive web service allows for a comparison of the gene
                    expression pattern between the UMAP and the realistic root layout (Gene Expression) and provides the
                    mapping for all cell types in the root (Annotation). One can click on the UMAP to see whereto in the
                    root the cells were mapped. To optimize web service efficiency, the number of cells on the
                    interactive
                    UMAP was reduced by grouping cells of the same type and similar developmental state into one entity.
                    All images can be downloaded (the button appears in the right upper part).
                    <br/>
                    <br/>
                    <b>Data:</b> In the beta version of the web service we provide an access to one integrated dataset
                    on Arabidopsis root.
                    It integrates the preprocessed data from seven studies: (
                    <Link href="https://doi.org/10.1104/pp.18.01482" rel="noopener noreferrer" target="_blank"
                          underline="none">
                        Ryu et al., 2019
                    </Link>), (
                    <Link href="https://doi.org/10.1126/science.aay4970" rel="noopener noreferrer"
                          target="_blank" underline="none">
                        Wendrich et al., 2020
                    </Link>), (
                    <Link href="https://doi.org/10.1016/j.molp.2019.04.004" rel="noopener noreferrer" target="_blank"
                          underline="none">
                        Zhang et al., 2019
                    </Link>), (
                    <Link href="https://doi.org/10.1016/j.devcel.2019.02.022" rel="noopener noreferrer" target="_blank"
                          underline="none">
                        Denyer et al., 2019
                    </Link>), (
                    <Link href="https://doi.org/10.1105/tpc.18.00785" rel="noopener noreferrer" target="_blank"
                          underline="none">
                        Jean-Baptiste et al. 2019
                    </Link>), (
                    <Link href="https://doi.org/10.1016/j.celrep.2019.04.054" rel="noopener noreferrer" target="_blank"
                          underline="none">
                        Shulse et al., 2019
                    </Link>), (
                    <Link href="https://doi.org/10.1016/j.devcel.2022.01.008" rel="noopener noreferrer" target="_blank"
                          underline="none">
                        Shahan et al., 2022
                    </Link>). The datasets were integrated using <Link href="https://doi.org/10.1016/j.cell.2021.04.048"
                                                                       rel="noopener noreferrer" target="_blank"
                                                                       underline="none">
                    Seurat</Link> toolkit. Our experts annotated cell types and sub-cell
                    types based on the marker genes expression. Longitudinal zonation was defined via pseudotime
                    inference using <Link href="https://bioconductor.org/packages/release/bioc/html/slingshot.html"
                                          rel="noopener noreferrer" target="_blank"
                                          underline="none">
                    Slingshot</Link> method.
                    <br/>
                    <br/>
                    <Divider sx={{bgcolor: "black", height: '0px', mt: 2, mb: 2}}/>
                    The root cell atlas is featured by the <Link
                    href={DEPARTMENT_OF_PLANT}
                    rel="noopener noreferrer" target="_blank"
                    underline="none">Department of Plant Systems Physiology</Link>, <Link
                    href={RIBES}
                    rel="noopener noreferrer" target="_blank"
                    underline="none">RIBES</Link>, <Link
                    href={RADBOUD_UNIVERSITY}
                    rel="noopener noreferrer" target="_blank"
                    underline="none">Radboud University</Link>.
                    This is a self-funded project. Thank you for your <Link
                    href={DONATION_URL}
                    rel="noopener noreferrer" target="_blank"
                    underline="none">support</Link> and ideas for improvement.
                    <br/>
                    <Link href="mailto: victoria.mironova@ru.nl" rel="noopener noreferrer" target="_blank"
                          underline="none">
                        Contact: Victoria Mironova
                    </Link>
                    <br/>
                    <br/>
                    The database version: 01122022.
                    <br/>
                    The web service works with Chrome and Safari web browsers.
                </DialogContentText>
            </DialogContent>
        </Dialog>
    }
}

export default AboutComponent