import {Gene, geneName} from "../models/Gene";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {Fragment, memo} from "react";

interface GenProps {
    options: Gene[]
    geneChanged: (newOption: Gene) => void,
    selectedOption: Gene | null
    geneInput: string
    loadOptions: (input: string) => void
    optionIsLoading: boolean
    isLoading: boolean
}


const GenSelect = (props: GenProps) => {
    let inputRef: any
    return (
        <Autocomplete
            sx={{minWidth: "250px"}}
            freeSolo
            disableClearable
            autoHighlight={true}
            value={props.selectedOption ?? undefined}
            options={props.options}
            onChange={(event, newValue) => {
                if (isString(newValue)) {
                    let gene = props.options.find(e => e.geneId === newValue)
                    if (gene) {
                        props.geneChanged(gene)
                    }
                } else {
                    if (newValue) {
                        props.geneChanged(newValue as Gene)
                    }
                }

                inputRef.blur()
            }}
            onInputChange={(event, newInputValue) => {
                props.loadOptions(newInputValue)
            }}
            disabled={props.isLoading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => geneName(option)}
            loading={props.optionIsLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onFocus={() => {
                        inputRef.select();
                    }}
                    inputRef={input => {
                        inputRef = input;
                    }}
                    label="Search Gene"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        endAdornment: (
                            <Fragment>
                                {props.optionIsLoading ? <CircularProgress color="inherit" size={12}/> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}


export default memo(GenSelect)

function isString(x: any): x is string {
    return typeof x === "string";
}