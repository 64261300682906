import {ColorMap} from "../models/ColorMap";
import SelectForm from "./SelectForm";
import {logMessage} from "../reportWebVitals";

interface ColorMapProps {
    options: ColorMap[]
    optionChanged: (newOption: ColorMap) => void,
    selectedOption: ColorMap | undefined
}

export const ColorMapSelect = (props: ColorMapProps) => {
    logMessage("render.ColorMapSelect")

    return (
        <SelectForm
            label={"Select ColorMap"}
            optional={false}
            minWidth={"220px"}
            options={props.options}
            optionChanged={e => props.optionChanged(e as ColorMap)}
            selectedOption={props.selectedOption}
        />
    )
}