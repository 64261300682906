import {GeneDataSource} from "../../../data/GeneDataSource";
import generateUmap from "../../rootatlas/data/UmapCreator";
import {AppFeature, groupBy} from "../../rootatlas/data/AppFeature";
import {provideLabeledMap, RootInfo, RootSectionColorMap} from "../../rootatlas/data/RootCreator";
import {DatasetInfoResponse} from "../../../data/DatasetInfoDataSource";
import {Cluster} from "../../../models/Cluster";
import {ClusterGroup, ClusterGroupType} from "../../../models/ClusterGroup";
import {Labeled} from "../../../models/Labeled";
import {Umap} from "../../../models/Umap";
import {ColorMap} from "../../../models/ColorMap";
import {Cell} from "../../../models/Cell";
import {Dataset} from "../../../models/Dataset";
import {ClusterModeRootInfo} from "../model/ClusterModeRootInfo";
import {ClusterUmapInfo} from "../model/ClusterUmapInfo";
import {logMessage} from "../../../reportWebVitals";

const equal = require('deep-equal');

export interface ClusterFeatureState {
    readonly currentSelectedGroup: ClusterGroup | null
    readonly currentSelectedClusters: Cluster[] | null
    readonly currentColorMap: ColorMap | null
    readonly groups: Map<ClusterGroupType, Array<ClusterGroup>> | null
    readonly currentUmap: Umap | null
    readonly labeledMap: RootSectionColorMap | null

    readonly clusters: Cluster[] | null
    readonly currentDataset: Dataset | null,
    readonly cells: Cell[] | null,
    readonly labeled: Labeled[] | null,
    readonly datasetInfo: DatasetInfoResponse | null
}

export class ClusterFeature {

    geneDataSource: GeneDataSource = new GeneDataSource()
    private currentState: ClusterFeatureState
    private readonly stateChanged: Array<(state: ClusterFeatureState) => void> = new Array<(state: ClusterFeatureState) => void>()

    constructor(appFeature: AppFeature) {
        this.addStateChangeListener = this.addStateChangeListener.bind(this)
        this.selectedClusters = this.selectedClusters.bind(this)
        this.selectedGroupChanged = this.selectedGroupChanged.bind(this)
        this.groupClusters = this.groupClusters.bind(this)
        this.hoveredClusterChanged = this.hoveredClusterChanged.bind(this)

        this.currentState = {
            currentSelectedGroup: null,
            currentSelectedClusters: null,
            clusters: null,
            currentColorMap: null,
            groups: null,
            currentUmap: null,
            labeledMap: null,

            labeled: appFeature.currentState.labeled,
            cells: appFeature.currentState.cells,
            currentDataset: appFeature.currentState.currentDataset,
            datasetInfo: appFeature.currentState.datasetInfo,
        }

        appFeature.addStateChangeListener(state => {
            const clusterGroups = state.datasetInfo?.clusterGroups;
            let groupsMap: Map<ClusterGroupType, Array<ClusterGroup>> | null = null

            if (clusterGroups) {
                groupsMap = groupBy(clusterGroups, e => e.type)
            }

            this.featureStateChanged({
                ...this.currentState,
                clusters: state.clusters,
                labeled: state.labeled,
                cells: state.cells,
                currentColorMap: state.appContent?.colorMaps[0] ?? null,
                currentDataset: state.currentDataset,
                datasetInfo: state.datasetInfo,
                groups: groupsMap
            })
        })
    }


    public addStateChangeListener(listener: (state: ClusterFeatureState) => void) {
        this.stateChanged.push(listener)
        listener(this.currentState)
    }

    public removeStateChangeListener(listener: (state: ClusterFeatureState) => void) {
        const index = this.stateChanged.indexOf(listener, 0);
        if (index > -1) {
            this.stateChanged.splice(index, 1);
        }
    }

    public selectedClusters(clusters: Cluster[]) {
        this.featureStateChanged({
                ...this.currentState,
                currentSelectedClusters: clusters
            }
        )
    }

    public selectedGroupChanged(group: ClusterGroup | undefined) {
        this.featureStateChanged({
                ...this.currentState,
                currentSelectedGroup: group ?? null,
                clusters: this.groupClusters(group) ?? this.currentState.datasetInfo?.clusters ?? null,
                currentSelectedClusters: this.groupClusters(group) ?? null,
            }
        )
    }

    public groupClusters(group: ClusterGroup | undefined): Cluster[] | undefined {
        if (group) {
            return this.currentState.datasetInfo?.clusters.filter(e => group.clusters.filter(i => e.id === i).length > 0)
        }

        return undefined
    }

    public hoveredClusterChanged(cluster: number | null) {
        if (this.currentState.currentSelectedClusters?.some(e => e.id === cluster) === true) {
            this.featureStateChanged({
                    ...this.currentState,
                    currentSelectedClusters: this.currentState.currentSelectedClusters?.filter(e => e.id !== cluster) ?? null,
                }
            )
        } else {
            let group = this.currentState.currentSelectedGroup
            let currentClusters = this.currentState.clusters
            let find = currentClusters?.find(e => e.id === cluster);

            if (!find) {
                group = null
                currentClusters = this.currentState.datasetInfo?.clusters ?? null
                find = currentClusters?.find(e => e.id === cluster);
            }

            if (!find) {
                return
            }

            let clusters = this.currentState.currentSelectedClusters;
            let result
            if (clusters) {
                result = Array.from(clusters);
                result.push(find)
            } else {
                result = [find]
            }

            this.featureStateChanged({
                    ...this.currentState,
                    currentSelectedGroup: group,
                    clusters: currentClusters,
                    currentSelectedClusters: result,
                }
            )
        }
    }

    private featureStateChanged(featureState: ClusterFeatureState) {
        this.regenerateUmapIfNeeded(featureState, this.currentState)
        this.regenerateLabeledMapIfNeeded(featureState, this.currentState)

        if (!equal(featureState, this.currentState)) {
            logMessage("update state")
            this.stateChanged.forEach(l => l(featureState))
        }

        this.currentState = featureState
    }


    private regenerateUmapIfNeeded(
        newFeatureState: ClusterFeatureState,
        currentFeatureState: ClusterFeatureState
    ): boolean {
        const prevUmapInfo: ClusterUmapInfo = umapInfo(currentFeatureState)
        const newUmapInfo: ClusterUmapInfo = umapInfo(newFeatureState)

        if (!equal(prevUmapInfo, newUmapInfo)) {
            logMessage("regenerateUmapIfNeeded")
            generateUmap(newUmapInfo)
                .then(umap => {
                    this.featureStateChanged(
                        {
                            ...this.currentState,

                            currentUmap: umap
                        }
                    )
                })

            return true
        }

        return false
    }

    private regenerateLabeledMapIfNeeded(
        newFeatureState: ClusterFeatureState,
        currentFeatureState: ClusterFeatureState
    ): boolean {
        const prevRootInfo: RootInfo = rootInfo(currentFeatureState)
        const newRootInfo: RootInfo = rootInfo(newFeatureState)

        if (!equal(prevRootInfo, newRootInfo)) {
            logMessage("regenerateLabeledMapIfNeeded")
            provideLabeledMap(newRootInfo)
                .then(map => {
                    this.featureStateChanged(
                        {
                            ...this.currentState,
                            labeledMap: map
                        }
                    )
                })

            return true
        }

        return false
    }
}

function rootInfo(state: ClusterFeatureState): ClusterModeRootInfo {
    return {
        mode: 'cluster',
        rootImage: state.datasetInfo?.rootImage,
        currentColorMap: state.currentColorMap,
        selectedClusters: state.currentSelectedClusters ?? [],
        clusters: state.clusters ?? [],
    }
}

function umapInfo(state: ClusterFeatureState): ClusterUmapInfo {
    return {
        mode: 'cluster',
        colorMap: state.currentColorMap,
        selectedClusters: state.currentSelectedClusters ?? [],
        clusters: state.datasetInfo?.clusters ?? [],
        cells: state.cells
    }
}