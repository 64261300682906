import {Component} from "react";
import {Box, Stack} from "@mui/material";
import {ColorMapSelect} from "../ColorMapSelect";
import MaxExpressionValueComponent from "../MaxExpressionValue";
import {GeneOntologyFeature, GeneOntologyFeatureState} from "./data/GeneOntologyFeature";
import {ColorMap} from "../../models/ColorMap";
import {GeneOntologyList} from "./model/GeneOntologyList";
import GeneOntologySelect from "../GenOntologySelectComponent";
import {GeneOntologyGroup} from "../../models/GeneOntology";
import {GeneOntologyGroupSelect} from "../GeneOntologyGroupSelect";

interface Props {
    readonly geneOntologyFeature: GeneOntologyFeature
}

interface State {
    readonly colorMaps: ColorMap[]
    readonly geneList: GeneOntologyList | null
    readonly loadingGeneInfo: boolean
    readonly selectedColorMap: ColorMap | undefined
    readonly maxClusterExpression: number
    readonly maxExpressionTypedValue: number
    readonly goGroups: GeneOntologyGroup[] | null
    readonly selectedGoGroup: GeneOntologyGroup | null
}

export class GeneOntologyHeaderComponent extends Component<Props, State> {

    geneFeature: GeneOntologyFeature
    listener: (state: GeneOntologyFeatureState) => void =
        state => {
            this.setState(
                {
                    goGroups: state.geneOntologyGroups,
                    selectedGoGroup: state.currentGeneOntologyGroup,
                    geneList: state.geneList,
                    colorMaps: state.colorMaps ?? [],
                    loadingGeneInfo: state.loadingGeneInfo,
                    selectedColorMap: state.selectedColorMap ?? undefined,
                    maxClusterExpression: state.currentMaxExpression,
                    maxExpressionTypedValue: state.maxExpressionTypedValue,
                }
            )
        }

    constructor(props: Props) {
        super(props);
        this.geneFeature = props.geneOntologyFeature

        this.state =
            {
                geneList: null,
                colorMaps: [],
                loadingGeneInfo: true,
                selectedColorMap: undefined,
                maxClusterExpression: 0,
                maxExpressionTypedValue: 0,
                goGroups: null,
                selectedGoGroup: null,
            }
    }

    componentDidMount() {
        this.geneFeature.addStateChangeListener(this.listener)
    }

    componentWillUnmount() {
        this.geneFeature.removeStateChangeListener(this.listener)
    }

    render() {
        const geneList = this.state.geneList;
        const goGroups = this.state.goGroups
        if (!geneList || !goGroups) {
            return <Box/>
        }

        return <Stack direction="row" spacing={2}>
            <GeneOntologyGroupSelect
                selectedOption={this.state.selectedGoGroup ?? undefined}
                options={goGroups}
                optionChanged={this.geneFeature.goGroupChanged}
            />
            <GeneOntologySelect
                genChanged={this.geneFeature.geneChanged}
                genInput={geneList.input}
                loadOptions={this.geneFeature.geneInputChanged}
                isLoading={this.state.loadingGeneInfo}
                optionIsLoading={geneList.isLoading}
                options={geneList.searchedGene}
                selectedOption={geneList.selectedGene}/>
            <ColorMapSelect
                selectedOption={this.state.selectedColorMap}
                options={this.state.colorMaps}
                optionChanged={this.geneFeature.colorMapChanged}
            />
            <MaxExpressionValueComponent
                maxExpressionTypedValue={this.state.maxExpressionTypedValue}
                isLoading={this.state.loadingGeneInfo}
                onChange={this.geneFeature.expressionChanged}
                maxExpression={this.state.maxClusterExpression}
            />
        </Stack>;
    }
}