import {initializeApp} from "firebase/app";
import {getAnalytics, logEvent} from "firebase/analytics";

export declare type ScreenType = 'main' | 'about' | 'start'

export class AnalyticsScreenLogger {

    private prevScreen: ScreenType | undefined

    public logScreenEvent(screen: ScreenType) {
        if (this.prevScreen === screen) {
            return
        }
        this.prevScreen = screen
        switch (screen) {
            case 'about':
                logEvent(
                    fbAnalytics,
                    'screen_view',
                    {
                        page_title: "About",
                        firebase_screen: "About",
                        firebase_screen_class: "AboutComponent"
                    }
                )
                break
            case 'start':
                logEvent(
                    fbAnalytics,
                    'screen_view',
                    {
                        page_title: "StartPage",
                        firebase_screen: "StartPage",
                        firebase_screen_class: "StartPageComponent"
                    }
                )
                break
            case 'main':
                logEvent(
                    fbAnalytics,
                    'screen_view',
                    {
                        page_title: "RootAtlas",
                        firebase_screen: "RootAtlas",
                        firebase_screen_class: "RootAtlasComponent"
                    }
                )
        }
    }
}

const firebaseConfig = {
    apiKey: "AIzaSyCPWmEVsq3z0jjZl51z-YePZms8MQ8ew_8",
    authDomain: "celcel-psp.firebaseapp.com",
    projectId: "celcel-psp",
    storageBucket: "celcel-psp.appspot.com",
    messagingSenderId: "618806468412",
    appId: process.env.REACT_APP_ANALYTICS_APP_ID,
    measurementId: process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const fbAnalytics = getAnalytics(firebaseApp);

export const analyticsLogger = new AnalyticsScreenLogger()