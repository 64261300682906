import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from "@mui/material";

export const theme =
    createTheme({
        palette: {
            primary: {
                light: '#efefef',
                main: '#bdbdbd',
                dark: '#8d8d8d',
                contrastText: '#000',
            },
            secondary: {
                light: '#ffff81',
                main: '#ffd54f',
                dark: '#c8a415',
                contrastText: '#000',
            },
        },
        typography: {
            h1: {
                fontSize: '26px',
            },
            h2: {
                fontSize: '24px',
            },
            h3: {
                fontSize: '22px',
            },
            h4: {
                fontSize: '20px',
            },
            h5: {
                fontSize: '18px',
            },
            h6: {
                fontSize: '16px',
            },
            subtitle1: {
                fontSize: '16px'
            },
            subtitle2: {
                fontSize: '16px'
            },
            body1: {
                fontSize: '16px'
            },
            body2: {
                fontSize: '16px'
            },
            caption: {
                fontSize: '16px'
            },
            button: {
                fontSize: '16px'
            },
            overline: {
                fontSize: '16px'
            },
        },
    });


ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <App/>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your rootatlas, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
