import {Component, ReactElement} from "react";
import {Stack} from "@mui/material";
import {ClusterFeature, ClusterFeatureState} from "./data/ClusterFeature";
import {ClusterGroup, ClusterGroupType} from "../../models/ClusterGroup";
import {ClusterGroupComponent} from "../ClusterGroup";
import {SelectClusterComponent} from "../SelectClusterComponent";
import {Cluster} from "../../models/Cluster";

interface AnnotationHeaderProps {
    readonly clusterFeature: ClusterFeature
}

interface AnnotationHeaderState {
    readonly selectedGroup: ClusterGroup | null
    readonly selectedClusters: Cluster[] | null
    readonly clusters: Cluster[] | null
    readonly groups: Map<ClusterGroupType, ClusterGroup[]> | null
}

export class AnnotationHeaderComponent extends Component<AnnotationHeaderProps, AnnotationHeaderState> {

    clusterFeature: ClusterFeature
    listener: (state: ClusterFeatureState) => void =
        state =>
            this.setState(
                {
                    selectedGroup: state.currentSelectedGroup,
                    selectedClusters: state.currentSelectedClusters,
                    clusters: state.clusters,
                    groups: state.groups
                }
            )

    constructor(props: AnnotationHeaderProps) {
        super(props);
        this.clusterFeature = props.clusterFeature
        this.state =
            {
                selectedGroup: null,
                selectedClusters: null,
                clusters: null,
                groups: null,
            }
    }

    componentDidMount() {
        this.clusterFeature.addStateChangeListener(this.listener)
    }

    componentWillUnmount() {
        this.clusterFeature.removeStateChangeListener(this.listener)
    }

    render() {
        let clusterGroups = new Array<ReactElement>()
        if (this.state.groups) {
            this.state.groups.forEach(((value, key) => {
                const selectedOption = this.state.selectedGroup;
                let option: ClusterGroup | undefined = undefined
                if (selectedOption?.type === key) {
                    option = selectedOption
                }
                clusterGroups.push(
                    <ClusterGroupComponent
                        key={key}
                        type={key}
                        selectedOption={option}
                        options={value ?? []}
                        optionChanged={this.clusterFeature.selectedGroupChanged}/>
                )
            }))
        }
        return <Stack direction="row" spacing={2}>
            {clusterGroups}
            <SelectClusterComponent
                key={"selectCluster"}
                selectedOptions={this.state.selectedClusters ?? []}
                options={this.state.clusters ?? []}
                optionChanged={this.clusterFeature.selectedClusters}
            />
        </Stack>;
    }
}