import {Component} from "react";
import {GeneOntologyFeature, GeneOntologyFeatureState} from "./data/GeneOntologyFeature";
import {Box, CircularProgress, Grid, Link, Typography} from "@mui/material";
import RootImageComponent from "../root_image/RootImageComponent";
import UmapComponent from "../UmapComponent";
import {Labeled} from "../../models/Labeled";
import {RootImage} from "../../models/RootImage";
import {Umap} from "../../models/Umap";
import {ColorMap} from "../../models/ColorMap";
import {Dataset} from "../../models/Dataset";
import {RootSectionColorMap} from "../rootatlas/data/RootCreator";
import {GeneOntologyList} from "./model/GeneOntologyList";


interface Props {
    readonly geneOntologyFeature: GeneOntologyFeature
}

interface State {
    readonly geneContentState: GeneOntologyContentState | null
}

interface GeneOntologyContentState {
    readonly geneList: GeneOntologyList
    readonly loadingGeneInfo: boolean
    readonly labeled: Array<Labeled>
    readonly currentRootImage: RootImage
    readonly currentUmap: Umap
    readonly labeledMap: RootSectionColorMap | null
    readonly maxClusterExpression: number
    readonly selectedDataset: Dataset
    readonly selectedColorMap: ColorMap | undefined
}

export class GeneOntologyContentComponent extends Component<Props, State> {

    geneOntologyFeature: GeneOntologyFeature
    listener: (state: GeneOntologyFeatureState) => void =
        state => {
            if (
                state.geneList &&
                state.labeled &&
                state.datasetInfo?.rootImage &&
                state.currentUmap &&
                state.currentMaxExpression &&
                state.currentDataset &&
                state.selectedColorMap) {
                this.setState(
                    {
                        geneContentState: {
                            geneList: state.geneList,
                            loadingGeneInfo: state.loadingGeneInfo,
                            labeled: state.labeled,
                            currentRootImage: state.datasetInfo?.rootImage,
                            currentUmap: state.currentUmap,
                            labeledMap: state.labeledMap,
                            maxClusterExpression: state.currentMaxExpression,
                            selectedDataset: state.currentDataset,
                            selectedColorMap: state.selectedColorMap,
                        }
                    }
                )
            } else {
                this.setState({
                    geneContentState: null
                })
            }
        }

    constructor(props: Props) {
        super(props);
        this.renderContent = this.renderContent.bind(this)
        GeneOntologyContentComponent.loading = GeneOntologyContentComponent.loading.bind(this)

        this.geneOntologyFeature = props.geneOntologyFeature
        this.state =
            {
                geneContentState: null,
            }
    }

    componentDidMount() {
        this.geneOntologyFeature.addStateChangeListener(this.listener)
    }

    componentWillUnmount() {
        this.geneOntologyFeature.removeStateChangeListener(this.listener)
    }

    render() {
        if (this.state.geneContentState) {
            return this.renderContent(this.state.geneContentState)
        } else {
            return GeneOntologyContentComponent.loading()
        }
    }

    private static loading() {
        return <Box sx={{
            position: 'absolute',
            zIndex: 'modal',
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }} pr={2} pl={2}>
            <CircularProgress/>
        </Box>;
    }

    private renderContent(state: GeneOntologyContentState) {
        const gene = state.geneList.selectedGene;
        if (!gene) {
            return <Box/>
        }
        let geneId = gene.geneOntologyId
        let geneIdLink = `https://www.ebi.ac.uk/QuickGO/term/${geneId}`
        let geneNames = <Typography/>
        if (gene.label) {
            geneNames = <Typography>GO Name: {gene.label}</Typography>
        }

        let label = gene.geneOntologyId
        let umapDescription =
            <Box>
                <Typography>GOID: <Link
                    href={geneIdLink}
                    rel="noopener noreferrer" target="_blank"
                    underline="none">{geneId}</Link></Typography>
                {geneNames}
            </Box>

        let opacity = state.loadingGeneInfo ? 0.3 : 1
        return <Box sx={{opacity: `${opacity}`, width: 1, minWidth: '1200px', maxWidth:'1800px'}}>
            <Grid container
                  spacing={2}
                  flexWrap={"nowrap"}
                  alignItems="stretch"
                  justifyContent={"center"}
                  pb={4} mt={0}>
                <Grid item xs={4}>
                    <RootImageComponent
                        fileName={`${geneId}_root_${state.selectedDataset.version}.png`}
                        dataset={state.selectedDataset}
                        label={label}
                        labeledList={state.labeled}
                        rootImage={state.currentRootImage}
                        mode={'main'}
                        labeledMap={state.labeledMap}
                        maxExpression={state.maxClusterExpression}
                        colorMap={state.selectedColorMap ?? null}/>
                </Grid>
                <Grid item xs={8}>
                    <UmapComponent
                        fileName={`${geneId}_UMAP_${state.selectedDataset.version}.png`}
                        description={umapDescription}
                        umap={state.currentUmap}
                        clusterHovered={this.geneOntologyFeature.hoveredClusterChanged}/>
                </Grid>
            </Grid>
        </Box>
    }
}
