import {SERVER_URL} from "./Network";
import {Cell} from "../models/Cell";

export interface CellResponse {
    readonly cells: Cell[]
}

export interface CellRequest {
    readonly datasetId: number
}

export class CellDataSource {
    cells = async (cellRequest: CellRequest): Promise<Cell[]> => {
        const response =
            await fetch(
                `${SERVER_URL}/${cellRequest.datasetId}/cell/all`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );

        return await response.json();
    }


}