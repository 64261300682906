import {Icon, IconProps} from "@mui/material";

const tissueIcon = `${process.env.PUBLIC_URL}/images/tissue.svg`;

export default function AnnotationIcon(props: IconProps) {
    return (
        <Icon
            {...props}>
            <img src={tissueIcon} alt={"donation"} style={{height: "100%"}}/>
        </Icon>
    );
}