import {memo} from "react";
import {
    Checkbox,
    FormControl, IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select, Stack
} from "@mui/material";
import {Cluster} from "../models/Cluster";
import ClearIcon from "@mui/icons-material/Clear";

interface SelectClusterProps {
    options: Cluster[]
    optionChanged: (newOption: Cluster[]) => void,
    selectedOptions: Cluster[],
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const SelectClusterComponent = memo(
    (props: SelectClusterProps) => {
        return (
            <Stack sx={{m: 1, width: 300, mt: 3}} direction={"row"}>
                <FormControl
                    sx={{width: '100%'}}
                >
                    <InputLabel id={"select-cluster"}>Select Cluster</InputLabel>
                    <Select
                        multiple
                        displayEmpty={true}
                        label={"Select Cluster"}
                        value={props.selectedOptions}
                        onChange={event => {
                            let selectedOpt = props.selectedOptions;
                            let clusters = (event.target.value as Array<any>)
                            let clusterId = clusters[clusters.length - 1] as number
                            const selected = selectedOpt.find(e => e.id === clusterId)

                            let selectedResult = Array.from(selectedOpt)
                            if (selected) {
                                selectedResult = selectedResult.filter(e => e.id !== selected.id)
                            } else {
                                selectedResult.push(props.options.find(e => e.id === clusterId)!!)
                            }

                            props.optionChanged(selectedResult)
                        }}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return "";
                            }
                            return selected.map(e => e.label)?.join(', ')
                        }
                        }
                        MenuProps={MenuProps}
                    >
                        {
                            props.options.map((cluster) => {
                                return (
                                    <MenuItem key={cluster.id} value={cluster.id}>
                                        <Checkbox checked={(props.selectedOptions.indexOf(cluster) ?? -1) > -1}/>
                                        <ListItemText primary={cluster.label}/>
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>

                <IconButton disableRipple onClick={() => {
                    props.optionChanged([])
                }}>
                    <ClearIcon/>
                </IconButton>
            </Stack>
        )
    }
)