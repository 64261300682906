import {LabeledColor, RootImage, RootSection} from "../../models/RootImage";
import React, {Component, memo, ReactElement, RefObject} from "react";
import {RootImageColorBar} from "./RootImageColorBar";
import {ColorMap} from "../../models/ColorMap";
import RootImageCanvas from "./RootImageCanvas";
import {Labeled} from "../../models/Labeled";
import {Box, Card, CardContent, CircularProgress, Grid, Stack,} from "@mui/material";
import DownloadImage from "../DownloadImage";
import {RootImageMode} from "../../models/AppContent";
import {Dataset} from "../../models/Dataset";

const equal = require('deep-equal');

export interface RootImageProps {
    readonly label: string
    readonly dataset: Dataset
    readonly rootImage: RootImage | null
    readonly labeledList: Array<Labeled> | null
    readonly colorMap: ColorMap | null
    readonly maxExpression: number | null
    readonly mode: RootImageMode
    readonly fileName: string
    readonly labeledMap: Map<RootSection, Array<LabeledColor>> | null
}

export interface RootImageState {
    showDownloadImage: boolean
}

class RootImageComponent extends Component<RootImageProps, RootImageState> {
    componentRef: RefObject<HTMLDivElement>

    constructor(props: RootImageProps) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            showDownloadImage: false
        }
    }

    render() {
        let rootImage = this.props.rootImage;
        let labeledMap = this.props.labeledMap;
        let colorMap = this.props.colorMap;
        let labeledList = this.props.labeledList;
        let maxExpression = this.props.maxExpression;
        let content;
        if (rootImage && labeledMap && colorMap && maxExpression !== null && labeledList) {
            let longScale = 0.5

            let longLabeled = labeledList.find(e => e.type === "labeled_long")!!;
            let longScaleHeight = longLabeled.rootImageData.height * longScale;
            let longScaleWidth = longLabeled.rootImageData.width * longScale;

            let crossHeight = (longScaleHeight - 14 * 8 - 136) / 6;
            let crossWidth = (longScaleWidth - 14 * 8 - 136) / 6;

            let long = rootImage.rootSections.find(e => e.labeledType === "labeled_long")!!;
            let cross = rootImage.rootSections.filter(e => e.labeledType !== "labeled_long")

            let rootImageSection: (
                section: RootSection,
                title: string,
                labeledMap: Map<RootSection, Array<LabeledColor>>,
                scale: number | null,
            ) => ReactElement =
                (section, title, labeledMap, scale) => {
                    let sectionLabeledMap = labeledMap.get(section)!!;
                    let labeled = labeledList!!.find(e => e.type === section.labeledType)!!;

                    return (
                        <div key={section.rootSection}>
                            <RootImageCanvas
                                rootImageData={labeled.rootImageData}
                                labeledMap={sectionLabeledMap}
                                title={title}
                                scale={scale ?? crossHeight / labeled.rootImageData.height}/>
                        </div>);
                }

            content =
                <Grid
                    container
                    sx={{minWidth: `${longScaleWidth + crossWidth}`}}
                    justifyContent={"center"}
                    alignItems="stretch">
                    <Grid container item justifyContent={"center"} xs={12}>
                        <Grid item xs={5} marginTop={2}>
                            {rootImageSection(long, this.props.label, labeledMap, longScale)}
                        </Grid>
                        <Grid item xs={5} marginTop={2}>
                            <Stack sx={{width: '100%'}} spacing={2}>
                                {
                                    cross.map(e => rootImageSection(e, e.label, this.props.labeledMap!!, null))
                                }
                            </Stack>
                        </Grid>

                        <Grid
                            item
                            xs={2}
                            justifyContent={"right"}
                            visibility={this.props.mode === 'main' ? 'visible' : 'hidden'}
                        >
                            <RootImageColorBar
                                colorMap={colorMap}
                                maxExpression={maxExpression}
                                height={longScaleHeight + 50}
                            />
                        </Grid>
                    </Grid>
                </Grid>
        } else {
            content = (
                <Box display="flex"
                     justifyContent="center"
                     alignItems="center"
                     minHeight="100%">
                    <CircularProgress/>
                </Box>
            )
        }
        return (
            <React.Fragment>
                <Card
                    onMouseEnter={() => {
                        this.setState({
                                ...this.state,
                                showDownloadImage: true
                            }
                        )
                    }}
                    onMouseLeave={() => {
                        this.setState({
                                ...this.state,
                                showDownloadImage: false
                            }
                        )
                    }}
                >
                    <CardContent sx={{height: '100%'}}>
                        <Box sx={{position: "relative"}}>
                            <DownloadImage
                                fileName={this.props.fileName}
                                componentRef={this.componentRef}
                                show={this.state.showDownloadImage && this.props.rootImage != null}/>
                            <Box ref={this.componentRef}>
                                {content}
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

export default memo(
    RootImageComponent,
    (prevProps, nextProps) => {
        return equal(prevProps, nextProps)
    }
);
