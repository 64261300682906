import {Labeled} from "../models/Labeled";
import {SERVER_URL} from "./Network";
import {logMessage} from "../reportWebVitals";


export interface LabeledResponse {
    readonly labeledList: Labeled[]
}


export class LabeledDataSource {
    loadLabeled = async (): Promise<LabeledResponse> => {
        const response =
            await fetch(
                `${SERVER_URL}/labeled/all`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );

        let result = await response.json();
        logMessage("Labeled loaded")
        logMessage(result)
        return result
    }


}