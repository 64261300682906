import {memo} from "react";
import {ClusterGroup, ClusterGroupType} from "../models/ClusterGroup";
import SelectForm from "./SelectForm";

interface ClusterGroupProps {
    options: ClusterGroup[]
    type: ClusterGroupType
    optionChanged: (newOption: ClusterGroup) => void,
    selectedOption: ClusterGroup | undefined
}

export const ClusterGroupComponent = memo(
    (props: ClusterGroupProps) => {
        let label: string
        switch (props.type) {
            case "ZONE":
                label = "Select Zone"
                break;
            case "CELL_TYPE":
                label = "Select Cell Type"
                break;

        }
        return (
            <SelectForm
                label={label}
                optional={true}
                minWidth={"150px"}
                options={props.options}
                optionChanged={e => props.optionChanged(e as ClusterGroup)}
                selectedOption={props.selectedOption}
            />
        )
    }
)