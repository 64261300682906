import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {Fragment, memo} from "react";
import {GeneOntology, geneOntologyName} from "../models/GeneOntology";

interface GenProps {
    options: GeneOntology[]
    genChanged: (newOption: GeneOntology) => void,
    selectedOption: GeneOntology | null
    genInput: string
    loadOptions: (input: string) => void
    optionIsLoading: boolean
    isLoading: boolean
}


const GeneOntologySelect = (props: GenProps) => {
    let inputRef: any
    return (
        <Autocomplete
            sx={{minWidth: "250px"}}
            freeSolo
            disableClearable
            autoHighlight={true}
            value={props.selectedOption ?? undefined}
            options={props.options}
            onChange={(event, newValue) => {
                if (isString(newValue)) {
                    let gene = props.options.find(e => e.geneOntologyId === newValue)
                    if (gene) {
                        props.genChanged(gene)
                    }
                } else {
                    if (newValue) {
                        props.genChanged(newValue as GeneOntology)
                    }
                }

                inputRef.blur()
            }}
            onInputChange={(event, newInputValue) => {
                props.loadOptions(newInputValue)
            }}
            disabled={props.isLoading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => {
                return geneOntologyName(option)
            }
            }
            loading={props.optionIsLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onFocus={() => {
                        inputRef.select();
                    }}
                    inputRef={input => {
                        inputRef = input;
                    }}
                    label=" Search GO term"
                    InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        endAdornment: (
                            <Fragment>
                                {props.optionIsLoading ? <CircularProgress color="inherit" size={12}/> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}


export default memo(GeneOntologySelect)

function isString(x: any): x is string {
    return typeof x === "string";
}