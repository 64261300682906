import {Protoplast} from "./Protoplast";

export interface Gene {
    readonly id: number
    readonly geneId: string
    readonly geneLabels: GeneLabel[] | null
    readonly protoplastStatus: Protoplast[] | null
}

export function geneName(gene: Gene): string {
    let geneLabels = gene.geneLabels

    if (!geneLabels) {
        return gene.geneId
    }

    const strings = geneLabels.filter(e => e.label.length > 0).map(e => e.label).join("/");
    return strings.length > 0 ? gene.geneId.concat("/", strings) : gene.geneId
}

export interface GeneLabel {
    readonly label: string
    readonly description: string,
}