import React, {useState} from 'react';
import '../App.css';
import {RootAtlasComponent} from "../components/rootatlas/RootAtlasComponent";
import {Box, Fade,} from "@mui/material";
import AboutComponent from "../components/about/AboutComponent";
import StartPageScreen from "./StartPageScreen";
import {openInNewTab} from "../reportWebVitals";
import {analyticsLogger, ScreenType} from "../analytics/AnalyticsScreenLogger";
import {DONATION_URL} from "../data/Network";

interface RootAtlasScreenParams {
    needToShowWelcomeScreen: boolean
}

function RootAtlasScreen(
    params: RootAtlasScreenParams
) {
    const [isShowAbout, setIsShowAbout] = useState(false)
    const [isShowWelcomeScreen, setShowWelcomeScreen] = useState(params.needToShowWelcomeScreen)

    const overflow = isShowWelcomeScreen ? "hidden" : "auto"

    let currentScreen: ScreenType
    if (isShowAbout) {
        currentScreen = 'about'
    } else if (isShowWelcomeScreen) {
        currentScreen = 'start'
    } else {
        currentScreen = 'main'
    }

    analyticsLogger.logScreenEvent(currentScreen)

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                overflow: overflow,
            }}>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                }}
            >
                <Fade mountOnEnter in={true} appear={true}>
                    <Box sx={{
                        width: "100%",
                        height: "100%",
                    }}>
                        <RootAtlasComponent
                            onHelpClick={() => setIsShowAbout(true)}
                            onStartPageOpenClick={() => setShowWelcomeScreen(true)}
                            onDonateClick={() => openInNewTab(DONATION_URL)}/>
                    </Box>
                </Fade>
                <AboutComponent
                    isShow={isShowAbout}
                    onClose={() => setIsShowAbout(false)}/>
            </Box>

            <StartPageScreen
                isVisible={isShowWelcomeScreen}
                onRootAtlasClicked={() => setShowWelcomeScreen(false)}/>
        </Box>
    )
}

export default RootAtlasScreen;
