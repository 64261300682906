import {SERVER_URL} from "./Network";
import {GeneOntology, GeneOntologyGroup} from "../models/GeneOntology";

interface GOExpressionRequest {
    readonly datasetId: number
    readonly geneOntologyId: number
}

export interface GOClusterExpression {
    readonly cluster: number
    readonly go: number
    readonly expression: number
}

export interface GOCellExpression {
    readonly cell: number
    readonly expression: number
}

export interface GOGroupDefault {
    readonly datasetId: number
    readonly geneOntologyId: number
}

export interface GOExpressionResponse {
    readonly clustersExpression: Array<GOClusterExpression>
    readonly cellExpression: Array<GOCellExpression>
    readonly nonNullCells: number
    readonly maxClusterExpression: number
}

export class GeneOntologyDataSource {

    loadGOGroups = async (): Promise<Array<GeneOntologyGroup>> => {
        const response =
            await fetch(
                `${SERVER_URL}/gene_ontology/groups`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );

        return await response.json();
    }

    loadDefault = async (request: GOGroupDefault): Promise<GeneOntology> => {
        const response =
            await fetch(
                `${SERVER_URL}/gene_ontology/${request.datasetId}/${request.geneOntologyId}/default`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );

        return await response.json();
    }

    loadGOCellExpression = async (request: GOExpressionRequest): Promise<GOExpressionResponse> => {
        const response =
            await fetch(
                `${SERVER_URL}/gene_ontology/${request.datasetId}/item/${request.geneOntologyId}/expression`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );

        return await response.json();
    }

    searchGO = async (
        input: string,
        datasetId: number,
        goGroupId: number,
    ): Promise<Array<GeneOntology>> => {
        const response =
            await fetch(
                `${SERVER_URL}/gene_ontology/${datasetId}/${goGroupId}/search?` + new URLSearchParams({q: input}).toString(),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );

        return await response.json();
    }
}