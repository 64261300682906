import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import RootAtlasScreen from "./screen/RootAtlasScreen";
import {fbAnalytics} from "./analytics/AnalyticsScreenLogger";
import {setUserProperties} from 'firebase/analytics';

function App() {
    setUserProperties(fbAnalytics, {environment: process.env.REACT_APP_ENV});
    return <BrowserRouter>
        <Routes>
            <Route path="/"
                   element={
                       <RootAtlasScreen needToShowWelcomeScreen={true}/>
                   }/>
            <Route path="/rootAtlas" element={<RootAtlasScreen needToShowWelcomeScreen={false}/>}/>
        </Routes>
    </BrowserRouter>
}

export default App;
