import {Box, Fade, Slide} from "@mui/material";
import StartPageComponent from "../components/start_page/StartPageComponent";
import React from "react";

interface StartPageProps {
    readonly onRootAtlasClicked: () => void
    readonly isVisible: boolean
}

function StartPageScreen(
    props: StartPageProps
) {
    return (
        <Fade
            in={props.isVisible}
            mountOnEnter
            unmountOnExit>
            <Box sx={{
                height: "100%",
                width: "100%",
                zIndex: 'modal',
                position: "fixed",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                background: 'lightgray',
            }}>
                <div/>
                <Slide
                    direction={"up"}
                    in={props.isVisible}
                    appear={false}>
                    <Box
                        sx={{
                            height: "100%",
                            display: "flex",
                            justifyItems: "center",
                            alignItems: "center",
                        }}>
                        <StartPageComponent
                            onRootAtlasClicked={props.onRootAtlasClicked}/>
                    </Box>
                </Slide>
            </Box>
        </Fade>

    )
}

export default StartPageScreen