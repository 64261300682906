import {Dataset} from "../models/Dataset";
import {memo} from "react";
import SelectForm from "./SelectForm";

interface DatasetProps {
    options: Dataset[]
    optionChanged: (newOption: Dataset) => void,
    selectedOption: Dataset | undefined
}

export const DatasetSelect = memo(
    (props: DatasetProps) => {
        return (
            <SelectForm
                label={"Select Dataset"}
                optional={false}
                minWidth={"150px"}
                options={props.options}
                optionChanged={e => props.optionChanged(e as Dataset)}
                selectedOption={props.selectedOption}
            />
        )
    }
)