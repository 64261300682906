import {RootImage} from "../models/RootImage";
import {Cluster} from "../models/Cluster";
import {Gene} from "../models/Gene";
import {SERVER_URL} from "./Network";
import {ClusterGroup} from "../models/ClusterGroup";
import {GeneOntology} from "../models/GeneOntology";

export interface DatasetInfoRequest {
    readonly datasetId: number
}

export interface DatasetInfoResponse {
    readonly rootImage: RootImage
    readonly clusters: Cluster[]
    readonly defaultGene: Gene
    readonly defaultGeneOntology: GeneOntology
    readonly clusterGroups: ClusterGroup[]
}


export class DatasetInfoDataSource {

    async loadDatasetInfo(request: DatasetInfoRequest): Promise<DatasetInfoResponse> {
        const response =
            await fetch(
                `${SERVER_URL}/dataset/item/${request.datasetId}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );

        return await response.json();
    }
}