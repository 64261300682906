import {Gene} from "../models/Gene";
import {SERVER_URL} from "./Network";

interface GeneExpressionRequest {
    readonly datasetId: number
    readonly geneId: number
}

export interface GeneClusterExpression {
    readonly cluster: number
    readonly gene: number
    readonly expression: number
}

export interface GeneCellExpression {
    readonly cell: number
    readonly expression: number
}

export interface GeneExpressionResponse {
    readonly clustersExpression: Array<GeneClusterExpression>
    readonly cellExpression: Array<GeneCellExpression>
    readonly nonNullCells: number
    readonly maxClusterExpression: number
    readonly totalCountCells: number
}

export class GeneDataSource {

    loadGeneExpression = async (request: GeneExpressionRequest): Promise<GeneExpressionResponse> => {
        const response =
            await fetch(
                `${SERVER_URL}/gene/${request.datasetId}/item/${request.geneId}/expression`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );

        return await response.json();
    }

    searchGene = async (input: string, datasetId: number): Promise<Array<Gene>> => {
        const response =
            await fetch(
                `${SERVER_URL}/gene/${datasetId}/search?`  + new URLSearchParams({q: input}).toString(),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );

        return await response.json();
    }
}