import {Component} from "react";
import {ClusterFeature, ClusterFeatureState} from "./data/ClusterFeature";
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import RootImageComponent from "../root_image/RootImageComponent";
import UmapComponent from "../UmapComponent";
import {Dataset} from "../../models/Dataset";
import {Cluster} from "../../models/Cluster";
import {ClusterGroupType} from "../../models/ClusterGroup";
import {Labeled} from "../../models/Labeled";
import {RootImage} from "../../models/RootImage";
import {Umap} from "../../models/Umap";
import {RootSectionColorMap} from "../rootatlas/data/RootCreator";
import {ColorMap} from "../../models/ColorMap";
import {logMessage} from "../../reportWebVitals";

interface AnnotationContentProps {
    readonly clusterFeature: ClusterFeature
}

interface AnnotationContentState {
    readonly clusterSnapshot: ClusterSnapshot | null
}

interface ClusterSnapshot {
    readonly selectedClusters: Cluster[] | null
    readonly labeled: Array<Labeled>
    readonly currentRootImage: RootImage
    readonly currentUmap: Umap
    readonly labeledMap: RootSectionColorMap | null
    readonly maxClusterExpression: number
    readonly selectedDataset: Dataset
    readonly colorMap: ColorMap
}

export class AnnotationContentComponent extends Component<AnnotationContentProps, AnnotationContentState> {

    clusterFeature: ClusterFeature
    listener: (state: ClusterFeatureState) => void =
        (state) => {
            if (
                state.labeled &&
                state.datasetInfo?.rootImage &&
                state.currentUmap &&
                state.currentDataset &&
                state.currentColorMap) {
                const snapshot: ClusterSnapshot =
                    {
                        selectedClusters: state.currentSelectedClusters,
                        labeled: state.labeled,
                        currentRootImage: state.datasetInfo?.rootImage,
                        currentUmap: state.currentUmap,
                        labeledMap: state.labeledMap,
                        maxClusterExpression: 10,
                        selectedDataset: state.currentDataset,
                        colorMap: state.currentColorMap
                    };
                logMessage("Cluster", snapshot)
                this.setState(
                    {
                        clusterSnapshot: snapshot
                    }
                )
            } else {
                logMessage("Cluster", null)
                this.setState({
                    clusterSnapshot: null
                })
            }
        };

    constructor(props: AnnotationContentProps) {
        super(props);
        this.clusterUmapDescription = this.clusterUmapDescription.bind(this)
        this.clusterSnapshot = this.clusterSnapshot.bind(this)
        AnnotationContentComponent.loading = AnnotationContentComponent.loading.bind(this)

        this.clusterFeature = props.clusterFeature
        this.state = {
            clusterSnapshot: null
        }
    }

    componentDidMount() {
        this.clusterFeature.addStateChangeListener(this.listener)
    }

    componentWillUnmount() {
        this.clusterFeature.removeStateChangeListener(this.listener)
    }

    setState<K extends keyof AnnotationContentState>(state: ((prevState: Readonly<AnnotationContentState>, props: Readonly<AnnotationContentProps>) => (Pick<AnnotationContentState, K> | AnnotationContentState | null)) | Pick<AnnotationContentState, K> | AnnotationContentState | null, callback?: () => void) {
        super.setState(state, callback);
    }

    render() {
        let clusterSnapshot = this.state.clusterSnapshot

        if (clusterSnapshot) {
            return this.clusterSnapshot(clusterSnapshot)
        } else {
            return AnnotationContentComponent.loading()
        }
    }

    private clusterSnapshot(clusterSnapshot: ClusterSnapshot) {
        let label
        let umapDescription
        if (clusterSnapshot.selectedClusters) {
            label = ""
            umapDescription = this.clusterUmapDescription(clusterSnapshot);
        } else {
            label = ""
            umapDescription = <Typography/>
        }

        return <Box sx={{width: 1, minWidth: '1200px', maxWidth: '1800px'}}>
            <Grid container
                  spacing={2}
                  flexWrap={"nowrap"}
                  justifyContent={"center"}
                  alignItems="stretch"
                  pb={4} mt={0}>
                <Grid item xs={4}>
                    <RootImageComponent
                        fileName={`selected_clusters_root_${clusterSnapshot.selectedDataset.version}.png`}
                        dataset={clusterSnapshot.selectedDataset}
                        label={label}
                        labeledList={clusterSnapshot.labeled}
                        rootImage={clusterSnapshot.currentRootImage}
                        mode={'cluster'}
                        labeledMap={clusterSnapshot.labeledMap}
                        maxExpression={clusterSnapshot.maxClusterExpression}
                        colorMap={clusterSnapshot.colorMap}/>
                </Grid>
                <Grid item xs={8}>
                    <UmapComponent
                        fileName={`selected_clusters_UMAP_${clusterSnapshot.selectedDataset.version}.png`}
                        description={umapDescription}
                        umap={clusterSnapshot.currentUmap}
                        clusterHovered={this.clusterFeature.hoveredClusterChanged}/>
                </Grid>
            </Grid>
        </Box>
    }

    private clusterUmapDescription(snapshot: ClusterSnapshot) {
        if (snapshot.selectedClusters === null) {
            return <Typography/>
        }

        const subCellType = snapshot.selectedClusters.filter(e => e.cellSubType.length > 0).map(e => e.cellSubType)?.join(", ")
        const types = new Map<ClusterGroupType, Set<string>>()
        snapshot.selectedClusters.forEach(e => {
            e.clustersGroup.forEach(i => {
                if (types.has(i.type)) {
                    types.set(i.type, types.get(i.type)!!.add(i.label))
                } else {
                    types.set(i.type, new Set<string>().add(i.label))
                }
            })
        })
        const cellTypes = types.get("CELL_TYPE")
        const zone = types.get("ZONE")
        return <Box>
            {cellTypes !== undefined ?
                <Typography>{`Cell Type: ${Array.from(cellTypes).join(", ")}`}</Typography> : undefined}
            {subCellType.length > 0 ? <Typography>Sub Cell Type: {subCellType}</Typography> : undefined}
            {zone !== undefined ? <Typography>{`Zone: ${Array.from(zone).join(", ")}`}</Typography> : undefined}
            <Typography marginTop={3}>
                Cell types and sub-cell types were annotated by our experts based on the marker genes expression.
                <br/>
                Longitudinal zonation was defined via pseudotime inference using Slingshot method.
            </Typography>
        </Box>
    }

    private static loading() {
        return <Box sx={{
            position: 'absolute',
            zIndex: 'modal',
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }} pr={2} pl={2}>
            <CircularProgress/>
        </Box>;
    }
}

