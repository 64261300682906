import {AppContentDataSource} from "../../../data/AppContentDataSource";
import {LabeledDataSource} from "../../../data/LabeledDataSource";
import {DatasetInfoDataSource, DatasetInfoResponse} from "../../../data/DatasetInfoDataSource";
import {Labeled} from "../../../models/Labeled";
import {AppContent, RootImageMode} from "../../../models/AppContent";
import {RootAtlasState} from "../RootAtlasComponent";
import {Dataset} from "../../../models/Dataset";
import {Cluster} from "../../../models/Cluster";
import {Cell} from "../../../models/Cell";
import {CellDataSource} from "../../../data/CellDataSource";
import {logEvent} from "firebase/analytics";
import {fbAnalytics} from "../../../analytics/AnalyticsScreenLogger";
import {logMessage} from "../../../reportWebVitals";

const equal = require('deep-equal');

export interface AppFeatureState {
    readonly appContent: AppContent | null
    readonly currentDataset: Dataset | null
    readonly labeled: Labeled[] | null,
    readonly cells: Cell[] | null,
    readonly clusters: Cluster[] | null
    readonly datasetInfo: DatasetInfoResponse | null,
    readonly mode: RootImageMode,
}

export class AppFeature {

    private readonly stateChanged: (state: RootAtlasState) => void
    private readonly events: Array<(state: AppFeatureState) => void> = []

    appContentDataSource = new AppContentDataSource()
    labeledDataSource = new LabeledDataSource()
    datasetInfoDataSource = new DatasetInfoDataSource()
    cellDataSource = new CellDataSource()

    public currentState: AppFeatureState = {
        appContent: null,
        currentDataset: null,
        cells: null,
        labeled: null,
        datasetInfo: null,
        mode: 'main',
        clusters: null,
    }

    constructor(stateChanged: (state: RootAtlasState) => void) {
        this.stateChanged = stateChanged
        this.featureStateChanged = this.featureStateChanged.bind(this)
        this.appState = this.appState.bind(this)
        this.loadInfo = this.loadInfo.bind(this)
        this.datasetChanged = this.datasetChanged.bind(this)
        this.onModeChanged = this.onModeChanged.bind(this)
        this.addStateChangeListener = this.addStateChangeListener.bind(this)
    }

    public addStateChangeListener(listener: (state: AppFeatureState) => void) {
        this.events.push(listener)
        listener(this.currentState)
    }

    public onModeChanged(mode: RootImageMode) {
        logMessage("onModeChanged", mode)
        logEvent(
            fbAnalytics,
            'select_content_mode',
            {
                content_type: 'mode_changed',
                item_id: mode,
            }
        )
        if (mode) {
            this.featureStateChanged({
                    ...this.currentState,
                    mode: mode,
                }
            )
        }
    }

    public appState(): RootAtlasState {
        return featureStateToAppState(this.currentState)
    }

    public loadInfo() {
        let load = async () => {
            let labeled = await this.labeledDataSource.loadLabeled()
            this.featureStateChanged(
                {
                    ...this.currentState,
                    labeled: labeled.labeledList
                }
            )

            let content = await this.appContentDataSource.loadAppContent()

            this.datasetChanged(content.datasets[0])

            this.featureStateChanged(
                {
                    ...this.currentState,
                    appContent: content,
                }
            )
        }
        load().then(() => {
        })
    }

    public datasetChanged(dataset: Dataset) {
        this.featureStateChanged(
            {
                ...this.currentState,
                currentDataset: dataset,
            }
        )

        this.cellDataSource.cells({
            datasetId: dataset.id
        }).then(r =>
            this.featureStateChanged({
                ...this.currentState,
                cells: r
            })
        )

        this.datasetInfoDataSource
            .loadDatasetInfo({
                    datasetId: dataset.id,
                }
            )
            .then(r => {
                    this.featureStateChanged(
                        {
                            ...this.currentState,
                            datasetInfo: r,
                            clusters: r.clusters ?? null,
                        }
                    )
                }
            )
    }

    private featureStateChanged(featureState: AppFeatureState) {
        let prevAppState = featureStateToAppState(this.currentState)
        let newAppState = featureStateToAppState(featureState)

        if (!equal(newAppState, prevAppState)) {
            logMessage("stateChanged", newAppState)
            this.stateChanged(newAppState)
        }

        this.currentState = featureState
        this.events.forEach(l => l(featureState))
    }
}

export function roundExpression(expression: number | null | undefined): number {
    if (expression) {
        return parseFloat(expression.toFixed(2))
    }

    return expression ?? 0.0
}


function featureStateToAppState(featureState: AppFeatureState): RootAtlasState {
    return {
        isLoading: featureState.appContent == null,
        datasets: featureState.appContent?.datasets ?? null,
        currentMode: featureState.mode,
        selectedDataset: featureState.currentDataset,
    }
}

export function timeout(ms: number): CancelablePromise<unknown> {
    let timeout: NodeJS.Timeout;
    let promise;

    promise = new Promise(function (resolve) {
        timeout = setTimeout(function () {
            resolve('timeout done');
        }, ms);
    });

    return {
        promise: promise,
        cancel: function () {
            clearTimeout(timeout);
        } //return a canceller as well
    };
}

export interface CancelablePromise<T> {
    promise: Promise<T>
    cancel: () => void
}

export function groupBy<K, T>(list: Array<T>, keyGetter: (item: T) => K): Map<K, Array<T>> {
    const map = new Map<K, Array<T>>();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}