import {Umap} from "../../../models/Umap";
import {GeneExpressionResponse} from "../../../data/GeneDataSource";
import {ColorMap, ColorRgb} from "../../../models/ColorMap";
import {Cell} from "../../../models/Cell";
import {Cluster} from "../../../models/Cluster";
import {GeneUmapInfo} from "../../gene/model/GeneUmapInfo";
import {ClusterUmapInfo} from "../../cluster/model/ClusterUmapInfo";
import {GeneOntologyUmapInfo} from "../../gene_ontology/model/GeneOntologyUmapInfo";
import {GOExpressionResponse} from "../../../data/GeneOntologyDataSource";
import {logMessage} from "../../../reportWebVitals";

export declare type UmapInfo = ClusterUmapInfo | GeneUmapInfo | GeneOntologyUmapInfo

export async function generateUmap(umapInfo: UmapInfo): Promise<Umap | null> {
    const clusterUmapInfo = umapInfo as ClusterUmapInfo
    const rootUmapInfo = umapInfo as GeneUmapInfo
    const geneOntologyUmapInfo = umapInfo as GeneOntologyUmapInfo

    switch (umapInfo.mode) {
        case "main":
            return generateRootUmap(rootUmapInfo)
        case "cluster":
            return generateClusterUmap(clusterUmapInfo)
        case "gene_ontology":
            return generateGOUmap(geneOntologyUmapInfo)
    }

    return null
}

async function generateClusterUmap(umapInfo: ClusterUmapInfo): Promise<Umap | null> {
    const map: ColorMap | null = umapInfo.colorMap
    const clusters = umapInfo.clusters
    const currentClusters = umapInfo.selectedClusters
    const cells = umapInfo.cells

    if (map && cells) {
        return {
            label: "UMAP",
            colorMap: map,
            coloredPosition: await provideClusterCellLabeledColor(map, cells, clusters, currentClusters),
        }
    }

    return null
}

async function generateRootUmap(
    umapInfo: GeneUmapInfo
): Promise<Umap | null> {
    const map: ColorMap | null = umapInfo.colorMap
    const geneExpression: GeneExpressionResponse | null = umapInfo.geneExpression
    const maxExpression = umapInfo.maxExpression
    let cellExpression = geneExpression?.cellExpression;
    let cells: Cell[] | null = umapInfo.cells

    if (cellExpression && map && geneExpression && cells) {
        return {
            label: "UMAP",
            colorMap: map,
            coloredPosition: await provideRootCellLabeledColor(geneExpression, cells, map, maxExpression ?? 0),
        }
    }

    return null
}

async function generateGOUmap(
    umapInfo: GeneOntologyUmapInfo
): Promise<Umap | null> {
    const map: ColorMap | null = umapInfo.colorMap
    const geneExpression: GOExpressionResponse | null = umapInfo.geneExpression
    const maxExpression = umapInfo.maxExpression
    let cellExpression = geneExpression?.cellExpression;
    let cells: Cell[] | null = umapInfo.cells

    if (cellExpression && map && geneExpression && cells) {
        return {
            label: "UMAP",
            colorMap: map,
            coloredPosition: await provideGOCellLabeledColor(geneExpression, cells, map, maxExpression ?? 0),
        }
    }

    return null
}

async function provideClusterCellLabeledColor(
    currentColorMap: ColorMap | null,
    cells: Cell[],
    clusters: Cluster[],
    currentClusters: Cluster[],
): Promise<Map<ColorRgb, Cell[]>> {
    let result = new Map<ColorRgb, Array<Cell>>()
    if (currentColorMap) {

        let nullExpressionCell = new Array<Cell>()
        let maxExpressionCell = new Array<Cell>()
        const nullClusters =
            clusters
                .filter(cl => !currentClusters.some(e => cl.id === e.id));
        nullClusters
            .forEach(e => nullExpressionCell.push.apply(nullExpressionCell, cells.filter(c => e.cells.includes(c.id))))

        currentClusters
            .forEach(e => maxExpressionCell.push.apply(maxExpressionCell, cells.filter(c => e.cells.includes(c.id))))

        result.set(currentColorMap.colorMap[0], nullExpressionCell)
        result.set(currentColorMap.colorMap[currentColorMap.colorMap.length - 1], maxExpressionCell)
    }

    return result
}

async function provideRootCellLabeledColor(
    geneExpression: GeneExpressionResponse,
    cellsEntities: Cell[],
    currentColorMap: ColorMap,
    maxExpression: number,
): Promise<Map<ColorRgb, Cell[]>> {
    let result = new Map<ColorRgb, Array<Cell>>()
    geneExpression
        .cellExpression
        .forEach((e => {
            let rgbIndex
            if (maxExpression === 0) {
                rgbIndex = 0
            } else {
                rgbIndex =
                    Math.min(
                        Math.floor((e.expression * (currentColorMap.colorMap.length - 1)) / maxExpression),
                        currentColorMap.colorMap.length - 1
                    )
            }

            let colorRgb = currentColorMap.colorMap[rgbIndex]
            let cells = result.get(colorRgb);
            if (cells) {
                const cell = cellsEntities.find(c => e.cell === c.id);
                if (cell) {
                    cells.push(cell)
                } else {
                    logMessage(`cell missed: ${e.cell}`)
                }
            } else {
                let cells = new Array<Cell>()
                const cell = cellsEntities.find(c => e.cell === c.id);
                if (cell) {
                    cells.push(cell)
                } else {
                    logMessage(`cell missed: ${e.cell}`)
                }
                result.set(colorRgb, cells)
            }
        }))

    return result
}

async function provideGOCellLabeledColor(
    geneExpression: GOExpressionResponse,
    cellsEntities: Cell[],
    currentColorMap: ColorMap,
    maxExpression: number,
): Promise<Map<ColorRgb, Cell[]>> {
    let result = new Map<ColorRgb, Array<Cell>>()
    geneExpression
        .cellExpression
        .forEach((e => {
            let rgbIndex
            if (maxExpression === 0) {
                rgbIndex = 0
            } else {
                rgbIndex =
                    Math.min(
                        Math.floor((e.expression * (currentColorMap.colorMap.length - 1)) / maxExpression),
                        currentColorMap.colorMap.length - 1
                    )
            }

            let colorRgb = currentColorMap.colorMap[rgbIndex]
            let cells = result.get(colorRgb);
            if (cells) {
                const cell = cellsEntities.find(c => e.cell === c.id);
                if (cell) {
                    cells.push(cell)
                } else {
                    logMessage(`cell missed: ${e.cell}`)
                }
            } else {
                let cells = new Array<Cell>()
                const cell = cellsEntities.find(c => e.cell === c.id);
                if (cell) {
                    cells.push(cell)
                } else {
                    logMessage(`cell missed: ${e.cell}`)
                }
                result.set(colorRgb, cells)
            }
        }))

    return result
}

export default generateUmap