import React, {Component, RefObject} from "react";
import {Box, IconButton, Tooltip, Zoom} from "@mui/material";
import {exportComponentAsJPEG} from "react-component-export-image";
import DownloadIcon from "@mui/icons-material/Download";

interface DownloadImageProps {
    readonly componentRef: RefObject<any>
    readonly show: boolean
    readonly fileName: string
}

class DownloadImage extends Component<DownloadImageProps, any> {

    render() {
        return (
            <Box sx={
                {
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    position: "absolute",
                    zIndex: "1",
                }
            }>
                <Zoom in={this.props.show}>
                    <Tooltip title="Download image">
                        <IconButton
                            disableRipple
                            onClick={() =>
                                exportComponentAsJPEG(
                                    this.props.componentRef,
                                    {fileName: this.props.fileName}
                                )
                            }
                        >
                            <DownloadIcon/>
                        </IconButton>
                    </Tooltip>
                </Zoom>
            </Box>
        );
    }
}

export default DownloadImage