import {AppContent} from "../models/AppContent";
import {SERVER_URL} from "./Network";

export class AppContentDataSource {

    async loadAppContent(): Promise<AppContent> {
        const response =
            await fetch(
                `${SERVER_URL}/appcontent`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                }
            );
        return await response.json()
    }

}
