import {Component, memo} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {Option} from "../models/Option";

interface SelectFormProps<T extends Option> {
    readonly optional: boolean
    readonly label: string
    readonly minWidth: string
    readonly options: T[]
    readonly optionChanged: (newOption: T) => void,
    readonly selectedOption: T | undefined
}

class SelectForm<T extends Option> extends Component<SelectFormProps<T>> {
    render() {
        let optional

        if (this.props.optional) {
            optional = (
                <MenuItem value={"_"}>Any</MenuItem>
            )
        }
        return (
            <FormControl key={this.props.label} sx={{minWidth: this.props.minWidth, display: 'flex'}}>
                <InputLabel>{this.props.label}</InputLabel>
                <Select
                    value={this.props.selectedOption?.id ?? "_"}
                    label={this.props.label}
                    onChange={e => this.props.optionChanged(this.props.options.find(item => item.id === e.target.value)!!)}
                >
                    {optional}
                    {this.props.options.map(e => (<MenuItem value={e.id} key={e.id}>{e.label}</MenuItem>))}
                </Select>
            </FormControl>
        )
    }
}

export default memo(SelectForm)