import {Icon, IconProps} from "@mui/material";

const smartFactoryIcon = `${process.env.PUBLIC_URL}/images/smart-factory.svg`;

export default function GeneOntologyIcon(props: IconProps) {
    return (
        <Icon
            {...props}>
            <img src={smartFactoryIcon} alt={"donation"} style={{height: "100%"}}/>
        </Icon>
    );
}