import {ReportHandler} from 'web-vitals';
import {LOG_ENABLED} from "./data/Network";

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import('web-vitals').then(({getCLS, getFID, getFCP, getLCP, getTTFB}) => {
            getCLS(onPerfEntry);
            getFID(onPerfEntry);
            getFCP(onPerfEntry);
            getLCP(onPerfEntry);
            getTTFB(onPerfEntry);
        });
    }
};

export function openInNewTab(url: string) {
    const win = window.open(url, '_blank');
    if (win != null) {
        win.focus();
    }
}

export default reportWebVitals;

export function logMessage(message?: any, ...optionalParams: any[]) {
    if (LOG_ENABLED) {
        console.log(message, optionalParams)
    }
}
