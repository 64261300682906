import {Component, ReactElement} from "react";
import {GeneFeature, GeneFeatureState} from "./data/GeneFeature";
import {Box, CircularProgress, Grid, Link, Typography} from "@mui/material";
import RootImageComponent from "../root_image/RootImageComponent";
import UmapComponent from "../UmapComponent";
import {Gene} from "../../models/Gene";
import {Labeled} from "../../models/Labeled";
import {RootImage} from "../../models/RootImage";
import {Umap} from "../../models/Umap";
import {ColorMap} from "../../models/ColorMap";
import {GeneList} from "./model/GeneList";
import {Dataset} from "../../models/Dataset";
import {RootSectionColorMap} from "../rootatlas/data/RootCreator";
import {Protoplast} from "../../models/Protoplast";
import {logMessage} from "../../reportWebVitals";


interface GeneContentProps {
    readonly geneFeature: GeneFeature
}

interface State {
    readonly geneContentState: GeneContentState | null
}

interface GeneContentState {
    readonly geneList: GeneList
    readonly loadingGeneInfo: boolean
    readonly labeled: Array<Labeled>
    readonly currentRootImage: RootImage
    readonly currentUmap: Umap
    readonly labeledMap: RootSectionColorMap | null
    readonly maxClusterExpression: number
    readonly selectedDataset: Dataset
    readonly selectedColorMap: ColorMap | undefined
}

export class GeneContentComponent extends Component<GeneContentProps, State> {

    geneFeature: GeneFeature
    listener: (state: GeneFeatureState) => void =
        state => {
            if (
                state.geneList &&
                state.labeled &&
                state.datasetInfo?.rootImage &&
                state.currentUmap &&
                state.currentMaxExpression != null &&
                state.currentDataset &&
                state.selectedColorMap) {
                this.setState(
                    {
                        geneContentState: {
                            geneList: state.geneList,
                            loadingGeneInfo: state.loadingGeneInfo,
                            labeled: state.labeled,
                            currentRootImage: state.datasetInfo?.rootImage,
                            currentUmap: state.currentUmap,
                            labeledMap: state.labeledMap,
                            maxClusterExpression: state.currentMaxExpression,
                            selectedDataset: state.currentDataset,
                            selectedColorMap: state.selectedColorMap,
                        }
                    }
                )
            } else {
                this.setState({
                    geneContentState: null
                })
            }
        }

    constructor(props: GeneContentProps) {
        super(props);
        this.renderContent = this.renderContent.bind(this)
        GeneContentComponent.loading = GeneContentComponent.loading.bind(this)

        this.geneFeature = props.geneFeature
        this.state =
            {
                geneContentState: null,
            }
    }

    componentDidMount() {
        this.geneFeature.addStateChangeListener(this.listener)
    }

    componentWillUnmount() {
        this.geneFeature.removeStateChangeListener(this.listener)
    }

    render() {
        logMessage("RootAtlas", "geneContentState:", this.state)

        if (this.state.geneContentState) {
            return this.renderContent(this.state.geneContentState)
        } else {
            return GeneContentComponent.loading()
        }
    }

    private static loading() {
        return <Box sx={{
            position: 'absolute',
            zIndex: 'modal',
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }} pr={2} pl={2}>
            <CircularProgress/>
        </Box>;
    }

    private renderContent(state: GeneContentState) {
        const gene = state.geneList.selectedGene;
        if (!gene) {
            return <Box/>
        }
        let geneId = gene.geneId
        let geneIdLink = `https://www.arabidopsis.org/servlets/TairObject?name=${geneId}&type=locus`
        let geneNames = <Typography/>
        if (gene.geneLabels) {
            if (gene.geneLabels.length > 0 && gene.geneLabels[0].label) {
                geneNames = <Typography>Gene Name: {gene.geneLabels.map(e => e.label)?.join(", ")}</Typography>
            }
        }

        let label = geneLabel(gene)
        let umapDescription =
            <Box>
                <Typography>GeneID: <Link
                    href={geneIdLink}
                    rel="noopener noreferrer" target="_blank"
                    underline="none">{geneId}</Link></Typography>
                {geneNames}
                <Typography>This gene expresses in {state.geneList.nonNullCells} out
                    of {state.geneList.cellsNumber} cells.</Typography>
                {state.geneList.selectedGene && protoplastResult(state.geneList.selectedGene.protoplastStatus ?? [], 'activated')}
                {state.geneList.selectedGene && protoplastResult(state.geneList.selectedGene.protoplastStatus ?? [], 'inhibited')}
            </Box>

        let opacity = state.loadingGeneInfo ? 0.3 : 1
        return <Box sx={{opacity: `${opacity}`, width: 1, minWidth: '1200px', maxWidth: '1800px'}} key={gene.geneId}>
            <Grid container
                  spacing={2}
                  flexWrap={"nowrap"}
                  alignItems="stretch"
                  justifyContent={"center"}
                  pb={4} mt={0}>
                <Grid item xs={4}>
                    <RootImageComponent
                        fileName={`${geneId}_root_${state.selectedDataset.version}.png`}
                        dataset={state.selectedDataset}
                        label={label}
                        labeledList={state.labeled}
                        rootImage={state.currentRootImage}
                        mode={'main'}
                        labeledMap={state.labeledMap}
                        maxExpression={state.maxClusterExpression}
                        colorMap={state.selectedColorMap ?? null}/>
                </Grid>
                <Grid item xs={8}>
                    <UmapComponent
                        fileName={`${geneId}_UMAP_${state.selectedDataset.version}.png`}
                        description={umapDescription}
                        umap={state.currentUmap}
                        clusterHovered={this.geneFeature.hoveredClusterChanged}/>
                </Grid>
            </Grid>
        </Box>
    }
}

function protoplastResult(protoplastList: Protoplast[], type: 'activated' | 'inhibited'): ReactElement {
    if (protoplastList.some(e => e.type === type)) {
        const resulted = protoplastList.filter(e => e.type === type);
        let links =
            resulted
                .map((e, index) => <><Link href={e.paperLink} rel="noopener noreferrer"
                                           target="_blank">{e.paperName}</Link>{resulted.length - 1 !== index ? ', ' : ''}</>)

        return <Typography>The gene is protoplast-{type}: YES ({links})</Typography>
    }

    return <Typography>The gene is protoplast-{type}: NO</Typography>
}

function geneLabel(selectedGene: Gene | null): string {
    if (selectedGene) {
        return selectedGene.geneId
    }

    return "";
}
